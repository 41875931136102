var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { connect } from "react-redux";
var mapState = function (state) { return ({
    Theme: state.UI.Theme,
}); };
var mapDispatch = {
    UpdateAppTheme: function (theme) { return ({
        type: "UPDATE_APP_THEME",
        theme: theme,
    }); },
};
var connector = connect(mapState, mapDispatch);
var ThemeSwitchLocal = /** @class */ (function (_super) {
    __extends(ThemeSwitchLocal, _super);
    function ThemeSwitchLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            __THEME_SWITCHING: false,
        };
        _this.updateTheme = _this.updateTheme.bind(_this);
        return _this;
    }
    ThemeSwitchLocal.prototype.updateTheme = function (theme) {
        var _this = this;
        if (this.state.__THEME_SWITCHING) {
            return;
        }
        if (this.props.Theme == theme) {
            return;
        }
        this.setState({
            __THEME_SWITCHING: true,
        }, function () {
            if (theme == "dark") {
                document.querySelector('html').classList.add('dark');
            }
            else {
                document.querySelector('html').classList.remove('dark');
            }
            _this.props.UpdateAppTheme(theme);
            setTimeout(function () {
                _this.setState({
                    __THEME_SWITCHING: false,
                });
            }, 400);
        });
    };
    ThemeSwitchLocal.prototype.render = function () {
        var _this = this;
        var Theme = this.props.Theme;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "theme-switch ".concat(Theme) },
                React.createElement("i", { onClick: function () {
                        _this.updateTheme("light");
                    }, className: "h-c icon-light_mode ".concat(Theme == "light" ? "active" : "") }),
                React.createElement("i", { onClick: function () {
                        _this.updateTheme("dark");
                    }, className: "h-c icon-dark_mode ".concat(Theme == "dark" ? "active" : "") })),
            React.createElement("div", { className: "theme-switch-description ".concat(Theme) },
                React.createElement("span", { style: { fontSize: "".concat(Theme == "dark" ? "0" : "") } }, "Light mode"),
                React.createElement("span", { style: { fontSize: "".concat(Theme == "light" ? "0" : "") } }, "Dark mode"))));
    };
    return ThemeSwitchLocal;
}(React.Component));
var ThemeSwitch = connector(ThemeSwitchLocal);
export default ThemeSwitch;
