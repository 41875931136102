var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import DropDown from 'components/dropdown/dropdown';
import AssetViewSwitcher from 'components/ux/assetViewSwitcher';
import { UpdateInput } from 'helpers/inputHandlers';
import { FORMDATA_REQUEST, USER_POST_REQUEST } from 'helpers/requests';
import { TimeSince } from 'helpers/timesince';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { store } from 'redux/store';
import DashboardTemplate from 'templates/dashboard';
var mapState = function (state) { return ({
    AssetView: state.UI.AssetView,
}); };
var connector = connect(mapState, null);
var ProjectsPage = /** @class */ (function (_super) {
    __extends(ProjectsPage, _super);
    function ProjectsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.updateSort = function (method) {
            var _a = method.split("-"), v = _a[0], d = _a[1];
            var p = _this.state.projects;
            if (v == "created") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.created < b.created ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.created > b.created ? 1 : -1); });
                }
            }
            if (v == "name") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); });
                }
            }
            if (_this.state.searchValue != "") {
                var sr = _this.state.searchResults;
                if (v == "created") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.created < b.created ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.created > b.created ? 1 : -1); });
                    }
                }
                if (v == "name") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); });
                    }
                }
                _this.setState({
                    searchResults: sr,
                });
            }
            _this.setState({
                sortValue: method,
            });
        };
        _this.updateSearchInput = function (e) {
            var value = e.target.value;
            _this.setState({
                searchValue: value,
                searchResults: _this.state.projects.filter(function (p) { return p.name.substring(0, value.length).toLowerCase() == value.toLowerCase(); })
            });
        };
        _this.updateProjects = function (project) {
            _this.setState(function (prev) { return ({
                projects: __spreadArray([project], prev.projects, true)
            }); });
        };
        _this.pinProject = function (id) {
            var _a = _this.state, projects = _a.projects, pinnedProjects = _a.pinnedProjects;
            projects.filter(function (p, i) { return p.id == id; })[0].pinned = true;
            USER_POST_REQUEST('pin-project', { project_id: id }, function (res) {
                _this.setState({
                    pinnedProjects: __spreadArray(__spreadArray([], projects.filter(function (p, i) { return p.id == id; }), true), pinnedProjects, true),
                });
            }, function (err) {
                console.log(err);
            });
        };
        _this.unpinProject = function (id) {
            var _a = _this.state, projects = _a.projects, pinnedProjects = _a.pinnedProjects;
            projects.filter(function (p, i) { return p.id == id; })[0].pinned = false;
            USER_POST_REQUEST('unpin-project', { project_id: id }, function (res) {
                _this.setState({
                    pinnedProjects: pinnedProjects.filter(function (p, i) { return p.id != id; })
                });
            }, function (err) {
                console.log(err);
            });
        };
        _this.loadProject = function (id) {
            window.location.href = "/project/".concat(id);
        };
        _this.addProject = function (p) {
            _this.setState(function (prev) { return ({
                projects: __spreadArray([p], prev.projects, true),
            }); });
        };
        var reduxState = store.getState();
        _this.state = {
            projects: [],
            pinnedProjects: [],
            modalActive: false,
            sortValue: "created-DESC",
            searchValue: "",
            searchResults: [],
        };
        _this.toggleModal = _this.toggleModal.bind(_this);
        _this.addProject = _this.addProject.bind(_this);
        //
        _this.pinProject = _this.pinProject.bind(_this);
        _this.unpinProject = _this.unpinProject.bind(_this);
        //
        _this.updateSort = _this.updateSort.bind(_this);
        _this.updateSearchInput = _this.updateSearchInput.bind(_this);
        return _this;
    }
    ProjectsPage.prototype.toggleModal = function () {
        this.setState(function (prev) { return ({
            modalActive: !prev.modalActive
        }); });
    };
    ProjectsPage.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST('get-projects', { get_pinned: true }, function (res) {
            console.log(res);
            _this.setState({
                projects: res.projects || [],
                pinnedProjects: res.pinned_projects || [],
            });
        }, function (err) {
            console.log(err);
        });
    };
    ProjectsPage.prototype.render = function () {
        var _this = this;
        var _a = this.state, projects = _a.projects, pinnedProjects = _a.pinnedProjects;
        var projectPreviewProps = {
            pinProject: this.pinProject,
            unpinProject: this.unpinProject,
            loadProject: this.loadProject,
        };
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container-fluid projects-page" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "breadcrumbs" },
                                React.createElement(Link, { to: "/", className: "font-alt" }, "<",
                                    " HOME"))),
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("div", { className: "projects-heading" }, "Projects"),
                            React.createElement("a", { className: "new-project h-c", onClick: this.toggleModal },
                                React.createElement("i", { className: "icon-projects" }),
                                "Create New Project")))),
                React.createElement("div", { className: "row pinned-projects-section" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("h2", { className: "font-alt-2 heading" },
                                React.createElement("i", { className: "icon-pinned" }),
                                "TOP PROJECTS")),
                        React.createElement("div", { className: "row" }, pinnedProjects.length == 0 ?
                            "No projects pinned yet"
                            :
                                pinnedProjects.map(function (project, index) {
                                    return (React.createElement(ProjectPreview, __assign({ key: index, project: project }, projectPreviewProps)));
                                })))),
                React.createElement("div", { className: "row asset-controls" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center justify-content-between" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(DropDown, { valueIdentifier: "SORT: ", value: this.state.sortValue, clickAction: this.updateSort, options: [{ text: "Name Z-A", value: "name-DESC" }, { text: "Name A-Z", value: "name-ASC" }, { text: "Newest", value: "created-DESC" }, { text: "Oldest", value: "created-ASC" }] })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("i", { className: "icon-search" }),
                                React.createElement("input", { className: "input search", placeholder: "Search Projects", onChange: this.updateSearchInput, value: this.state.searchValue })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(AssetViewSwitcher, null))))),
                React.createElement("div", { className: "row projects-list" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" }, this.props.AssetView == "list" ?
                            React.createElement("div", { className: "col-12" },
                                React.createElement("table", { className: "assets-table" },
                                    React.createElement("tbody", null, this.state.searchValue != "" ?
                                        this.state.searchResults.map(function (p, i) {
                                            return React.createElement(ProjectTablePreview, { unpinProject: _this.unpinProject, pinProject: _this.pinProject, key: i, project: p, viewProject: _this.loadProject });
                                        }) :
                                        projects.map(function (p, i) {
                                            return React.createElement(ProjectTablePreview, { unpinProject: _this.unpinProject, pinProject: _this.pinProject, key: i, project: p, viewProject: _this.loadProject });
                                        }))))
                            :
                                this.state.searchValue != "" ?
                                    this.state.searchResults.map(function (p, i) {
                                        return React.createElement(ProjectPreview, { key: i, unpinProject: _this.unpinProject, pinProject: _this.pinProject, project: p, loadProject: _this.loadProject });
                                    }) :
                                    projects.map(function (p, i) {
                                        return React.createElement(ProjectPreview, { key: i, unpinProject: _this.unpinProject, pinProject: _this.pinProject, project: p, loadProject: _this.loadProject });
                                    })))),
                React.createElement(NewProjectModal, { projectsCount: this.state.projects.length, updateProjects: this.updateProjects, toggleModal: this.toggleModal, addProject: this.addProject, modalActive: this.state.modalActive }))));
    };
    return ProjectsPage;
}(React.Component));
export default connector(ProjectsPage);
var ProjectPreview = /** @class */ (function (_super) {
    __extends(ProjectPreview, _super);
    function ProjectPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectPreview.prototype.render = function () {
        var _a = this.props, project = _a.project, loadProject = _a.loadProject, pinProject = _a.pinProject, unpinProject = _a.unpinProject;
        return (React.createElement("div", { className: "project-preview col-xl-4 col-lg-6 col-md-12 col-12" },
            React.createElement("span", { onClick: project.pinned ? function () { unpinProject(project.id); } : function () { pinProject(project.id); }, className: "pin-project ".concat(project.pinned ? "pinned" : "") },
                React.createElement("i", { className: "icon-pinned" })),
            React.createElement("div", { className: "row wrapper", onClick: function () { loadProject(project.id); } },
                React.createElement("div", { className: "content row" },
                    React.createElement("div", { className: "thumbnail" },
                        React.createElement("img", { src: project.thumbnail == "" || project.thumbnail == null ? "/images/cover_images/1.png" : project.thumbnail })),
                    React.createElement("div", { className: "info" },
                        React.createElement("div", { className: "row info-content-wrapper align-items-center" },
                            React.createElement("h2", { className: "name" }, project.name),
                            React.createElement("h4", { className: "font-alt-2" },
                                project.ideas,
                                " IDEAS")))))));
    };
    return ProjectPreview;
}(React.Component));
var NewProjectModal = /** @class */ (function (_super) {
    __extends(NewProjectModal, _super);
    function NewProjectModal(props) {
        var _this = _super.call(this, props) || this;
        _this.updateImage = function (e) {
            _this.setState({
                image: e.target.files[0] || null,
            });
        };
        _this.state = {
            image: null,
            name: '',
            submitting: false,
            collaborators: [],
            groupCollaborators: [],
            errors: [],
        };
        _this.updateInput = UpdateInput.bind(_this);
        _this.createProject = _this.createProject.bind(_this);
        _this.clearImage = _this.clearImage.bind(_this);
        _this.inputRef = React.createRef();
        return _this;
    }
    NewProjectModal.prototype.clearImage = function () {
        var _this = this;
        this.setState({
            image: null,
        }, function () {
            _this.inputRef.current.value = null;
        });
    };
    NewProjectModal.prototype.createProject = function () {
        var _this = this;
        this.setState({
            errors: [],
            submitting: true,
        });
        var _a = this.state, image = _a.image, name = _a.name;
        var errors = [];
        var fd = new FormData();
        name.length == 0 ? errors.push("You need to set a name") : fd.append("name", name);
        name.length > 100 ? errors.push("Name can't be longer than 100 characters") : '';
        if (image != null) {
            image.size > 2000000 ? errors.push("Thumbnail size must be less than 2MB") : fd.append("thumbnail", image);
        }
        if (errors.length > 0) {
            this.setState({
                errors: errors,
                submitting: false,
            });
            return;
        }
        FORMDATA_REQUEST('create-project', fd, function (res) {
            _this.setState({
                image: null,
                name: ''
            });
            _this.props.addProject(res.project);
            _this.props.toggleModal();
            _this.setState({
                submitting: false,
            });
        }, function (err) {
            _this.setState({
                errors: err.response.data.errors,
            });
        });
    };
    NewProjectModal.prototype.render = function () {
        var _this = this;
        // if(false) {
        //     if(this.props.projectsCount >= 12) {
        //         return (
        //             <div className={`modal new-project-modal ${this.props.modalActive ? "active" : ""}`}>
        //             <div className="modal-content">
        //                 <div className="container"> 
        //                     <div className="row">
        //                         <div className="col-12 t-center">
        //                             <h1 className="font-alt" style={{fontWeight: 100}}>You have exceeded the maximum number of available projects for our trial. </h1>
        //                             <hr className="tp" /> 
        //                             <h3 className="font-alt" style={{fontWeight: 100}}>Please Contact us @ <a href="mailto:sales@ideablock.com" style={{color: "var(--yellow-green)"}}>sales@ideablock.com</a> to apply to join our enterprise version.</h3>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="modal-bg" onClick={() => {this.props.toggleModal()}}></div>
        //         </div>
        //         )
        //     }
        // }
        return (React.createElement("div", { className: "modal new-project-modal ".concat(this.props.modalActive ? "active" : "") },
            React.createElement("div", { className: "modal-content" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h1", { className: "heading font-alt" }, "Project Name:"),
                            React.createElement("input", { placeholder: "Name...", type: "text", name: "name", value: this.state.name, onChange: this.updateInput }),
                            React.createElement("h1", { className: "heading font-alt" }, "Project Image:"),
                            this.state.image != null ?
                                React.createElement("div", { className: "image-preview" },
                                    React.createElement("div", { className: "cancel-heading h-c" },
                                        React.createElement("a", { className: "font-alt-2", onClick: this.clearImage }, "Remove X")),
                                    React.createElement("img", { src: URL.createObjectURL(this.state.image) }))
                                : null,
                            React.createElement("input", { ref: this.inputRef, type: "file", accept: "image/*", onChange: this.updateImage }),
                            React.createElement("h2", { className: "sub-heading" }, "(optional, if none selected, it will be assigned a random thumbnail which can be updated later)")),
                        React.createElement("div", { className: "col-12 submit" },
                            React.createElement("button", { onClick: function () { _this.props.toggleModal(); }, className: "btn btn-cancel" }, "Cancel"),
                            React.createElement("span", { className: "btn-separator" }),
                            React.createElement("button", { onClick: this.createProject, disabled: this.state.name.length == 0 || this.state.submitting, className: "btn btn-blue" }, "Create Project"))))),
            React.createElement("div", { className: "modal-bg", onClick: function () { _this.props.toggleModal(); } })));
    };
    return NewProjectModal;
}(React.Component));
var ProjectTablePreview = /** @class */ (function (_super) {
    __extends(ProjectTablePreview, _super);
    function ProjectTablePreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectTablePreview.prototype.render = function () {
        var _a = this.props, project = _a.project, viewProject = _a.viewProject, pinProject = _a.pinProject, unpinProject = _a.unpinProject;
        return (React.createElement("tr", null,
            React.createElement("td", { onClick: function () { viewProject(project.id); } },
                React.createElement("img", { className: "thumbnail", src: project.thumbnail == "" || project.thumbnail == null ? "/images/cover_images/1.png" : project.thumbnail }),
                " "),
            React.createElement("td", { onClick: function () { viewProject(project.id); } },
                React.createElement("b", null, project.name)),
            React.createElement("td", { onClick: function () { viewProject(project.id); }, className: "font-alt" },
                project.idea_count || project.ideas,
                " IDEAS"),
            React.createElement("td", { onClick: function () { viewProject(project.id); }, className: "updated" },
                "Updated ",
                TimeSince(project.updated),
                " ago"),
            React.createElement("td", { className: "pin" },
                React.createElement("span", { onClick: project.pinned ? function () { unpinProject(project.id); } : function () { pinProject(project.id); }, className: "pin-project ".concat(project.pinned ? "pinned" : "") },
                    React.createElement("i", { className: "icon-pinned" })))));
    };
    return ProjectTablePreview;
}(React.Component));
