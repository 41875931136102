var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { USER_POST_REQUEST } from "helpers/requests";
import { TimeSince } from "helpers/timesince";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DashboardTemplate from "templates/dashboard";
import GroupSettingsOverview from "./groupSettingsOverview";
var mapState = function (state) { return ({
    Organization: state.App.Organization,
    User: state.App.User,
    Socket: state.App.Socket,
}); };
var connector = connect(mapState, null);
var GroupPage = /** @class */ (function (_super) {
    __extends(GroupPage, _super);
    function GroupPage(props) {
        var _this = _super.call(this, props) || this;
        _this.updateGroup = function (g) {
            _this.setState({
                currentGroup: g,
                thumbnail: g.thumbnail,
                name: g.name,
            });
        };
        _this.state = {
            id: "",
            name: "",
            thumbnail: "",
            ideas: [],
            projects: [],
            members: [],
            idea_count: 0,
            user_count: 0,
            project_count: 0,
            created: 0,
            updated: 0,
            group_loaded: false,
            modalOpen: false,
            currentGroup: null,
        };
        _this.toggleModal = _this.toggleModal.bind(_this);
        return _this;
    }
    GroupPage.prototype.toggleModal = function () {
        var modalOpen = this.state.modalOpen;
        this.setState({
            modalOpen: !modalOpen,
        });
    };
    GroupPage.prototype.componentDidMount = function () {
        var _this = this;
        var pn = window.location.pathname.split("/");
        if (pn.length != 3) {
            return;
        }
        var group = pn[2];
        USER_POST_REQUEST("get-group", { group: group }, function (res) {
            _this.setState({
                ideas: res.ideas || [],
                members: res.members || [],
                name: res.name || "",
                thumbnail: res.thumbnail || "",
                id: res.id,
                group_loaded: true,
                updated: res.updated,
                created: res.created,
                projects: res.projects || [],
                currentGroup: res,
            });
        }, function (err) {
            console.log(err);
        });
    };
    GroupPage.prototype.render = function () {
        var _a = this.state, group_loaded = _a.group_loaded, name = _a.name, thumbnail = _a.thumbnail, created = _a.created, updated = _a.updated, id = _a.id;
        if (!group_loaded) {
            return React.createElement(DashboardTemplate, null);
        }
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container-fluid organization-page-new group-page" },
                React.createElement("div", { className: "row group-header" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 breadcrumbs" },
                                React.createElement(Link, { to: "/groups", className: "font-alt" }, "<",
                                    " ALL GROUPS"))),
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("div", { className: "col-auto thumb" },
                                React.createElement("img", { src: thumbnail == null || thumbnail == ""
                                        ? "/images/cover_images/1.png"
                                        : thumbnail })),
                            React.createElement("div", { className: "col" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("h1", { className: "title font-alt" }, name)),
                                    React.createElement("div", { className: "col-12 time" },
                                        React.createElement("p", null,
                                            "Created: ",
                                            TimeSince(created),
                                            " ago",
                                            React.createElement("span", { className: "btn-separator" }),
                                            "|",
                                            React.createElement("span", { className: "btn-separator" }),
                                            "Last Updated: ",
                                            TimeSince(updated),
                                            " ago")),
                                    React.createElement("div", { className: "col-12 lower" },
                                        React.createElement("div", { className: "row justify-content-between" },
                                            React.createElement("div", { className: "col-auto" }, true ? (React.createElement("a", { className: "btn btn-blue-alt", onClick: this.toggleModal },
                                                React.createElement("i", { className: "icon-settings" }),
                                                " Settings")) : null)))))),
                        React.createElement("div", { className: "row", style: { marginTop: "15px" } },
                            React.createElement("h1", null, "Members"),
                            this.state.members == null ||
                                this.state.members.length == 0 ? (React.createElement("h2", null, "No members in this group, try adding some.")) : (React.createElement("div", { className: "w-full flex flex-col" },
                                React.createElement("div", { className: "flex font-semibold border-b border-gray-300 pb-2 mb-2" },
                                    React.createElement("div", { className: "w-1/2 " }, "Name:"),
                                    React.createElement("div", { className: "w-1/2 " }, "Added:")),
                                this.state.members.map(function (gm, index) { return (React.createElement("div", { key: index, className: "flex py-2   border-gray-100" },
                                    React.createElement("div", { className: "w-1/2 " }, gm.name == null || gm.name == ""
                                        ? "".concat(gm.first_name, " ").concat(gm.last_name)
                                        : gm.name),
                                    React.createElement("div", { className: "w-1/2 " }, TimeSince(gm.created)))); }))
                            // <table className="table group-members-table">
                            //   <thead>
                            //     <tr>
                            //       <td>Name: </td>
                            //       <td>Added: </td>
                            //     </tr>
                            //   </thead>
                            //   <tbody>
                            //     {this.state.members.map((gm: any, index: number) => {
                            //       return (
                            //         <tr key={index}>
                            //           <td>
                            //             {gm.name == null || gm.name == ""
                            //               ? `${gm.first_name} ${gm.last_name}`
                            //               : gm.name}
                            //           </td>
                            //           <td>{TimeSince(gm.created)}</td>
                            //         </tr>
                            //       );
                            //     })}
                            //   </tbody>
                            // </table>
                            )))),
                React.createElement("div", { className: "modal group-settings-modal ".concat(this.state.modalOpen ? "active" : "") },
                    React.createElement("div", { className: "modal-content" },
                        React.createElement(GroupSettingsOverview, { members: this.props.Organization.users, updateGroup: this.updateGroup, currentGroup: this.state.currentGroup })),
                    React.createElement("div", { className: "modal-bg", onClick: this.toggleModal })))));
    };
    return GroupPage;
}(React.Component));
export default connector(GroupPage);
