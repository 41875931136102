var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UpdateInput } from 'helpers/inputHandlers';
import { FORMDATA_REQUEST } from 'helpers/requests';
import React from 'react';
var NewGroupModal = /** @class */ (function (_super) {
    __extends(NewGroupModal, _super);
    function NewGroupModal(props) {
        var _this = _super.call(this, props) || this;
        _this.updateAvatar = function (e) {
            _this.setState({
                avatar: e.target.files[0]
            });
        };
        _this.state = {
            name: "",
            avatar: null,
            errors: [],
        };
        _this.updateInput = UpdateInput.bind(_this);
        _this.updateAvatar = _this.updateAvatar.bind(_this);
        _this.clearAvatar = _this.clearAvatar.bind(_this);
        _this.submit = _this.submit.bind(_this);
        _this.fileRef = React.createRef();
        return _this;
    }
    NewGroupModal.prototype.clearAvatar = function () {
        this.fileRef.current.value = "";
        this.setState({
            avatar: null,
        });
    };
    NewGroupModal.prototype.submit = function () {
        var _this = this;
        var _a = this.state, name = _a.name, avatar = _a.avatar;
        if (name == "") {
            this.setState({
                errors: ["A group name is required"]
            });
            return;
        }
        var fd = new FormData;
        fd.append("name", name);
        if (avatar != null) {
            fd.append("thumbnail", avatar);
        }
        FORMDATA_REQUEST('create-group', fd, function (res) {
            _this.props.addGroup(res);
            _this.props.toggle();
        }, function (err) {
            console.log(err);
        });
    };
    NewGroupModal.prototype.render = function () {
        var _a = this.props, open = _a.open, toggle = _a.toggle;
        return (React.createElement("div", { className: "modal new-group-modal ".concat(open ? "active" : "") },
            React.createElement("div", { className: "modal-content container-fluid" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row header justify-content-between align-items-center" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("h1", { className: "font-alt" }, "New Group")),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("button", { className: "btn btn-cancel-alt", onClick: toggle }, "Close"))),
                        React.createElement("div", { className: "row form" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("label", { className: "font-alt-2" }, "Group Name"),
                                React.createElement("input", { className: 'text-current', onChange: this.updateInput, type: "text", value: this.state.name, name: "name" }),
                                React.createElement("label", { className: "font-alt-2" }, "Group Image (optional)"),
                                React.createElement("input", { ref: this.fileRef, type: "file", onChange: this.updateAvatar, name: "avatar" })),
                            React.createElement("div", { className: "col-12 thumb-preview" }, this.state.avatar != null ?
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("img", { src: URL.createObjectURL(this.state.avatar) })),
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("hr", { className: "tp" }),
                                        React.createElement("button", { className: "btn btn-cancel-alt", onClick: this.clearAvatar }, "Clear Thumbnail")))
                                : null),
                            React.createElement("div", { className: "col-12 submit" },
                                React.createElement("button", { className: "btn btn-blue", onClick: this.submit }, "Create Group")))))),
            React.createElement("div", { className: "modal-bg", onClick: toggle })));
    };
    return NewGroupModal;
}(React.Component));
export default NewGroupModal;
