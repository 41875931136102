import React, { useState } from "react";
import { POST_REQUEST } from "../../helpers/requests";
import { EmptyNav } from "templates/EmptyNav";
var ResetPassword = function () {
    var _a = useState(""), password = _a[0], setPassword = _a[1];
    var _b = useState(""), confirmPassword = _b[0], setConfirmPassword = _b[1];
    var _c = useState([]), errors = _c[0], setErrors = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var handleSubmit = function () {
        if (password !== confirmPassword) {
            setErrors(["Passwords do not match"]);
            return;
        }
        var params = new URLSearchParams(window.location.search);
        var token = params.get("token");
        setIsLoading(true);
        POST_REQUEST("set-new-password", {
            password: password,
            token: token,
        }, function (res) {
            window.location.href = "/dashboard";
        }, function (err) {
            console.log(err.response);
            setErrors(err.response.data.errors || ["Something went wrong"]);
            setIsLoading(false);
        });
    };
    return (React.createElement(EmptyNav, null,
        React.createElement("div", { style: {
                display: "flex",
                width: "100%",
                marginTop: "20px",
                alignContent: "center",
                justifyContent: "center",
            } },
            React.createElement("div", { className: "set-password" },
                React.createElement("h1", null, "SET New PASSWORD"),
                React.createElement("div", { className: "password-form" },
                    React.createElement("input", { type: "password", placeholder: "Password", value: password, onChange: function (e) { return setPassword(e.target.value); }, className: "password-input" }),
                    React.createElement("input", { type: "password", placeholder: "Confirm Password", value: confirmPassword, onChange: function (e) { return setConfirmPassword(e.target.value); }, className: "password-input" }),
                    errors.length > 0 && (React.createElement("div", { className: "errors" }, errors.map(function (error, index) { return (React.createElement("div", { key: index, className: "error" }, error)); }))),
                    React.createElement("button", { onClick: handleSubmit, style: { backgroundColor: "#42A109" }, className: "btn btn-green", disabled: isLoading },
                        isLoading ? "Saving..." : "Save",
                        React.createElement("span", { style: { marginLeft: "5px" }, className: "arrow" }, "\u2192"))))),
        React.createElement("style", null, "\n          button:disabled {\n            opacity: 0.7;\n            cursor: not-allowed;\n          }\n        ")));
};
export default ResetPassword;
