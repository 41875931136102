var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import DashboardTemplate from "templates/dashboard";
import { Link } from "react-router-dom";
import { USER_POST_REQUEST } from "helpers/requests";
import { TimeSince } from "helpers/timesince";
import { connect } from "react-redux";
import DropDown from "components/dropdown/dropdown";
import AssetViewSwitcher from "components/ux/assetViewSwitcher";
import ProjectSettingsOverview from "./projectSettingsOverview";
var mapStateToProps = function (state) { return ({
    isAdmin: state.App.Organization.admin,
    isLeader: state.App.Organization.leader,
    userID: state.App.User.id,
    users: state.App.Organization.users,
    groups: state.App.Organization.groups,
    AssetView: state.UI.AssetView,
}); };
var mapDispatchToProps = {};
var connector = connect(mapStateToProps, mapDispatchToProps);
var ProjectPageLocal = /** @class */ (function (_super) {
    __extends(ProjectPageLocal, _super);
    function ProjectPageLocal(props) {
        var _this = this;
        var pn = window.location.pathname.split("/");
        if (pn.length != 3) {
            return _this;
        }
        var project = pn[2];
        _this = _super.call(this, props) || this;
        _this.updateProject = function (project) {
            _this.setState({
                name: project.name,
                thumbnail: project.thumbnail,
            });
        };
        _this.updateSort = function (method) {
            var _a = method.split("-"), v = _a[0], d = _a[1];
            var p = _this.state.ideas;
            if (v == "created") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.created < b.created ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.created > b.created ? 1 : -1); });
                }
            }
            if (v == "name") {
                if (d == "DESC") {
                    p.sort(function (a, b) {
                        return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
                    });
                }
                else {
                    p.sort(function (a, b) {
                        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                    });
                }
            }
            if (_this.state.searchValue != "") {
                var sr = _this.state.searchResults;
                if (v == "created") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.created < b.created ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.created > b.created ? 1 : -1); });
                    }
                }
                if (v == "name") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) {
                            return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
                        });
                    }
                    else {
                        sr.sort(function (a, b) {
                            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                        });
                    }
                }
                _this.setState({
                    searchResults: sr,
                });
            }
            _this.setState({
                sortValue: method,
            });
        };
        _this.updateSearchInput = function (e) {
            var value = e.target.value;
            _this.setState({
                searchValue: value,
                searchResults: _this.state.ideas.filter(function (p) {
                    return (p.title.substring(0, value.length).toLowerCase() ==
                        value.toLowerCase());
                }),
            });
        };
        _this.loadIdea = function (id) {
            window.location.href = "/idea/".concat(id);
        };
        _this.selectCurrentGroup = function (id) {
            var currentGroupsSelected = _this.state.currentGroupsSelected;
            currentGroupsSelected.indexOf(id) != -1
                ? _this.setState({
                    currentGroupsSelected: currentGroupsSelected.filter(function (gid) {
                        return gid != id;
                    }),
                })
                : _this.setState({
                    currentGroupsSelected: __spreadArray(__spreadArray([], currentGroupsSelected, true), [id], false),
                });
        };
        _this.selectCurrentUser = function (id) {
            var currentUsersSelected = _this.state.currentUsersSelected;
            currentUsersSelected.indexOf(id) != -1
                ? _this.setState({
                    currentUsersSelected: currentUsersSelected.filter(function (uid) {
                        return uid != id;
                    }),
                })
                : _this.setState({ currentUsersSelected: __spreadArray(__spreadArray([], currentUsersSelected, true), [id], false) });
        };
        _this.selectUser = function (id) {
            var usersSelected = _this.state.usersSelected;
            usersSelected.indexOf(id) != -1
                ? _this.setState({
                    usersSelected: usersSelected.filter(function (uid) {
                        return id != uid;
                    }),
                })
                : _this.setState({ usersSelected: __spreadArray(__spreadArray([], usersSelected, true), [id], false) });
        };
        _this.selectGroup = function (id) {
            var groupsSelected = _this.state.groupsSelected;
            groupsSelected.indexOf(id) != -1
                ? _this.setState({
                    groupsSelected: groupsSelected.filter(function (uid) {
                        return id != uid;
                    }),
                })
                : _this.setState({ groupsSelected: __spreadArray(__spreadArray([], groupsSelected, true), [id], false) });
        };
        _this.state = {
            id: project,
            name: "",
            thumbnail: "",
            ideas: [],
            idea_count: 0,
            created: 0,
            updated: 0,
            creator: "",
            modalOpen: false,
            usersSelected: [],
            groupsSelected: [],
            projectUsers: [],
            projectGroups: [],
            currentUsersSelected: [],
            currentGroupsSelected: [],
            sortValue: "created-DESC",
            searchValue: "",
            searchResults: [],
        };
        _this.toggleModal = _this.toggleModal.bind(_this);
        _this.addSelectedToProject = _this.addSelectedToProject.bind(_this);
        _this.removeSelectedFromProject = _this.removeSelectedFromProject.bind(_this);
        _this.selectCurrentGroup = _this.selectCurrentGroup.bind(_this);
        _this.selectCurrentUser = _this.selectCurrentUser.bind(_this);
        _this.selectUser = _this.selectUser.bind(_this);
        _this.selectGroup = _this.selectGroup.bind(_this);
        _this.clearCurrentSelected = _this.clearCurrentSelected.bind(_this);
        _this.clearSelected = _this.clearSelected.bind(_this);
        //
        _this.updateSort = _this.updateSort.bind(_this);
        _this.updateSearchInput = _this.updateSearchInput.bind(_this);
        _this.updateProject = _this.updateProject.bind(_this);
        return _this;
    }
    ProjectPageLocal.prototype.toggleModal = function () {
        var modalOpen = this.state.modalOpen;
        this.setState({ modalOpen: !modalOpen });
    };
    ProjectPageLocal.prototype.addSelectedToProject = function () {
        var _this = this;
        var _a = this.state, usersSelected = _a.usersSelected, groupsSelected = _a.groupsSelected;
        if (usersSelected.length > 0) {
            var projectUsers_1 = this.state.projectUsers;
            var users_1 = this.props.users;
            USER_POST_REQUEST("add-members-to-project", { project: this.state.id, users: usersSelected }, function (res) {
                _this.setState({
                    projectUsers: __spreadArray(__spreadArray([], projectUsers_1, true), users_1.filter(function (u) {
                        return usersSelected.indexOf(u.id) != -1;
                    }), true),
                    usersSelected: [],
                });
            }, function (err) {
                console.log(err);
            });
        }
        if (groupsSelected.length > 0) {
            var projectGroups_1 = this.state.projectGroups;
            var groups_1 = this.props.groups;
            USER_POST_REQUEST("add-groups-to-project", { project: this.state.id, groups: groupsSelected }, function (res) {
                _this.setState({
                    projectGroups: __spreadArray(__spreadArray([], projectGroups_1, true), groups_1.filter(function (g) {
                        return groupsSelected.indexOf(g.id) != -1;
                    }), true),
                    groupsSelected: [],
                });
            }, function (err) {
                console.log(err);
            });
        }
    };
    ProjectPageLocal.prototype.clearSelected = function () {
        this.setState({
            usersSelected: [],
            groupsSelected: [],
        });
    };
    ProjectPageLocal.prototype.clearCurrentSelected = function () {
        this.setState({
            currentGroupsSelected: [],
            currentUsersSelected: [],
        });
    };
    ProjectPageLocal.prototype.removeSelectedFromProject = function () {
        var _this = this;
        var _a = this.state, currentUsersSelected = _a.currentUsersSelected, currentGroupsSelected = _a.currentGroupsSelected;
        if (currentUsersSelected.length > 0) {
            var projectUsers_2 = this.state.projectUsers;
            USER_POST_REQUEST("remove-members-from-project", { project: this.state.id, users: currentUsersSelected }, function (res) {
                _this.setState({
                    projectUsers: __spreadArray([], projectUsers_2.filter(function (pu) {
                        return currentUsersSelected.indexOf(pu.id) == -1;
                    }), true),
                    currentUsersSelected: [],
                });
            }, function (err) {
                console.log(err);
            });
        }
        if (currentGroupsSelected.length > 0) {
            var projectGroups_2 = this.state.projectGroups;
            USER_POST_REQUEST("remove-groups-from-project", { project: this.state.id, groups: currentGroupsSelected }, function (res) {
                _this.setState({
                    projectGroups: __spreadArray([], projectGroups_2.filter(function (pg) {
                        return currentGroupsSelected.indexOf(pg.id) == -1;
                    }), true),
                    currentGroupsSelected: [],
                });
            }, function (err) {
                console.log(err);
            });
        }
    };
    ProjectPageLocal.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST("get-project", { project: this.state.id }, function (res) {
            console.log(res);
            _this.setState({
                name: res.name,
                thumbnail: res.thumbnail,
                created: res.created,
                updated: res.updated,
                ideas: res.ideas,
                creator: res.creator,
                projectGroups: res.groups || [],
                projectUsers: res.users || [],
            });
        }, function (err) {
            console.log(err);
        });
    };
    ProjectPageLocal.prototype.render = function () {
        var _this = this;
        var _a = this.props, isLeader = _a.isLeader, isAdmin = _a.isAdmin, userID = _a.userID;
        var _b = this.state, id = _b.id, name = _b.name, created = _b.created, updated = _b.updated, ideas = _b.ideas, thumbnail = _b.thumbnail, creator = _b.creator;
        var ModalProps = {
            name: name,
            groups: this.props.groups,
            users: this.props.users,
            projectUsers: this.state.projectUsers,
            projectGroups: this.state.projectGroups,
            currentUsersSelected: this.state.currentUsersSelected,
            currentGroupsSelected: this.state.currentGroupsSelected,
            usersSelected: this.state.usersSelected,
            groupsSelected: this.state.groupsSelected,
            selectCurrentGroup: this.selectCurrentGroup,
            selectUser: this.selectUser,
            selectGroup: this.selectGroup,
            selectCurrentUser: this.selectCurrentUser,
            removeSelectedFromProject: this.removeSelectedFromProject,
            addSelectedToProject: this.addSelectedToProject,
            clearSelected: this.clearSelected,
            clearCurrentSelected: this.clearCurrentSelected,
        };
        var hasPerms = isLeader || isAdmin || creator == userID;
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container-fluid project-page" },
                React.createElement("div", { className: "row header" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 breadcrumbs" },
                                React.createElement(Link, { to: "/projects", className: "font-alt" }, "<",
                                    " ALL PROJECTS"))),
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("div", { className: "col-auto thumb" },
                                React.createElement("img", { src: thumbnail == null || thumbnail == ""
                                        ? "/images/cover_images/1.png"
                                        : thumbnail })),
                            React.createElement("div", { className: "col" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("h1", { className: "title font-alt" }, name)),
                                    React.createElement("div", { className: "col-12 time" },
                                        React.createElement("p", null,
                                            "Created: ",
                                            TimeSince(created),
                                            " ago",
                                            React.createElement("span", { className: "btn-separator" }),
                                            "|",
                                            React.createElement("span", { className: "btn-separator" }),
                                            "Last Updated: ",
                                            TimeSince(updated),
                                            " ago")),
                                    React.createElement("div", { className: "col-12 lower" },
                                        React.createElement("div", { className: "row justify-content-between" },
                                            React.createElement("div", { className: "col-auto" }, hasPerms ? (React.createElement("a", { className: "btn btn-blue-alt", onClick: this.toggleModal },
                                                React.createElement("i", { className: "icon-settings" }),
                                                " Settings")) : null),
                                            React.createElement("div", { className: "col-auto" },
                                                React.createElement(Link, { to: "/create-idea?project=".concat(id), className: "font-alt-2 project-create-idea welcome-link btn btn-blue" },
                                                    React.createElement("span", { className: "icon-add" }),
                                                    " Add Idea"))))))))),
                React.createElement("div", { className: "row asset-controls" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center justify-content-between" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(DropDown, { valueIdentifier: "SORT: ", value: this.state.sortValue, clickAction: this.updateSort, options: [
                                        { text: "Name Z-A", value: "name-DESC" },
                                        { text: "Name A-Z", value: "name-ASC" },
                                        { text: "Newest", value: "created-DESC" },
                                        { text: "Oldest", value: "created-ASC" },
                                    ] })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("i", { className: "icon-search" }),
                                React.createElement("input", { className: "input search", placeholder: "Search Ideas", onChange: this.updateSearchInput, value: this.state.searchValue })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(AssetViewSwitcher, null))))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "container project-content" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" }, ideas.length == 0 ? (React.createElement("h2", { className: "heading font-alt-2" }, "No ideas in this project, yet.")) : null)),
                        React.createElement("div", { className: "row" }, this.props.AssetView == "list" ? (React.createElement("div", { className: "col-12" },
                            React.createElement("table", { className: "assets-table" },
                                React.createElement("tbody", null, this.state.searchValue != ""
                                    ? this.state.searchResults.map(function (p, i) {
                                        return (React.createElement(TableIdeaPreview, { key: i, idea: p, loadIdea: _this.loadIdea }));
                                    })
                                    : this.state.ideas.map(function (p, i) {
                                        return (React.createElement(TableIdeaPreview, { key: i, idea: p, loadIdea: _this.loadIdea }));
                                    }))))) : this.state.searchValue != "" ? (this.state.searchResults.map(function (p, i) {
                            return (React.createElement(IdeaPreview, { key: i, idea: p, loadIdea: _this.loadIdea }));
                        })) : (this.state.ideas.map(function (p, i) {
                            return (React.createElement(IdeaPreview, { key: i, idea: p, loadIdea: _this.loadIdea }));
                        })))),
                    React.createElement(ProjectSettingsModal, __assign({ updateProject: this.updateProject, project: {
                            id: this.state.id,
                            name: this.state.name,
                            thumbnail: this.state.thumbnail,
                        } }, ModalProps, { modalOpen: this.state.modalOpen, toggleModal: this.toggleModal }))))));
    };
    return ProjectPageLocal;
}(React.Component));
var ProjectSettingsModal = /** @class */ (function (_super) {
    __extends(ProjectSettingsModal, _super);
    function ProjectSettingsModal(props) {
        var _this = _super.call(this, props) || this;
        _this.updateView = function (v) {
            _this.setState({ view: v });
        };
        _this.state = {
            view: "overview",
        };
        _this.updateView = _this.updateView.bind(_this);
        return _this;
    }
    ProjectSettingsModal.prototype.render = function () {
        var _this = this;
        var view = this.state.view;
        var _a = this.props, modalOpen = _a.modalOpen, name = _a.name, toggleModal = _a.toggleModal, users = _a.users, groups = _a.groups, projectUsers = _a.projectUsers, projectGroups = _a.projectGroups, currentGroupsSelected = _a.currentGroupsSelected, currentUsersSelected = _a.currentUsersSelected;
        return (React.createElement("div", { className: "modal project-settings-modal ".concat(modalOpen ? "active" : "") },
            React.createElement("div", { className: "modal-bg", onClick: toggleModal }),
            React.createElement("div", { className: "modal-content" },
                React.createElement("div", { className: "row header" },
                    React.createElement("div", { className: "container" },
                        React.createElement("h1", { className: "font-light font-alt" },
                            name,
                            " Settings"))),
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row header-row" },
                        React.createElement("div", { onClick: function () {
                                _this.updateView("overview");
                            }, className: "col-4 font-alt-2 t-center option ".concat(view == "overview" ? "active" : "") }, "Overview"),
                        React.createElement("div", { onClick: function () {
                                _this.updateView("add");
                            }, className: "col-4 font-alt-2 t-center option ".concat(view == "add" ? "active" : "") }, "Add Users / Groups"),
                        React.createElement("div", { onClick: function () {
                                _this.updateView("update");
                            }, className: "col-4 font-alt-2 t-center option ".concat(view == "update" ? "active" : "") }, "Update / Delete")),
                    view == "overview" ? (React.createElement(React.Fragment, null,
                        currentGroupsSelected.length + currentUsersSelected.length >
                            0 ? (React.createElement("div", { className: "row controls" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("button", { className: "btn btn-cancel-alt", onClick: this.props.removeSelectedFromProject }, "Remove Selected From Project")),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("button", { className: "btn btn-blue-alt", onClick: this.props.clearCurrentSelected }, "Clear Selection")))) : null,
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-6 list" },
                                React.createElement("h2", { className: "font-light font-alt" }, "Project Users"),
                                projectUsers.map(function (u, i) {
                                    return (React.createElement("h3", { className: "".concat(currentUsersSelected.indexOf(u.id) != -1
                                            ? "selected"
                                            : ""), onClick: function () {
                                            _this.props.selectCurrentUser(u.id);
                                        }, key: i },
                                        u.first_name,
                                        " ",
                                        u.last_name));
                                })),
                            React.createElement("div", { className: "col-6 list" },
                                React.createElement("h2", { className: "font-light font-alt" }, "Project Groups"),
                                projectGroups.map(function (g, i) {
                                    return (React.createElement("h3", { className: "".concat(currentGroupsSelected.indexOf(g.id) != -1
                                            ? "selected"
                                            : ""), onClick: function () {
                                            _this.props.selectCurrentGroup(g.id);
                                        }, key: i }, g.name));
                                }))))) : null,
                    view == "add" ? (React.createElement(React.Fragment, null,
                        this.props.usersSelected.length +
                            this.props.groupsSelected.length >
                            0 ? (React.createElement("div", { className: "row controls" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("button", { className: "btn btn-blue-alt", onClick: this.props.addSelectedToProject }, "Add Selected to Project")),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("button", { className: "btn btn-cancel-alt", onClick: this.props.clearSelected }, "Clear Selection")))) : null,
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-6 list" },
                                React.createElement("h2", { className: "font-light font-alt" }, "Org Users"),
                                users.map(function (u, i) {
                                    if (projectUsers.filter(function (pu) {
                                        return pu.id == u.id;
                                    }).length != 0) {
                                        return null;
                                    }
                                    return (React.createElement("h3", { className: "".concat(_this.props.usersSelected.indexOf(u.id) != -1
                                            ? "selected"
                                            : ""), onClick: function () {
                                            _this.props.selectUser(u.id);
                                        }, key: i },
                                        u.first_name,
                                        " ",
                                        u.last_name));
                                })),
                            React.createElement("div", { className: "col-6 list" },
                                React.createElement("h2", { className: "font-light font-alt" }, "Org Groups "),
                                groups.map(function (g, i) {
                                    console.log(g);
                                    if (projectGroups.filter(function (pg) {
                                        return pg.id == g.id;
                                    }).length != 0) {
                                        return null;
                                    }
                                    return (React.createElement("h3", { className: "".concat(_this.props.groupsSelected.indexOf(g.id) != -1
                                            ? "selected"
                                            : ""), onClick: function () {
                                            _this.props.selectGroup(g.id);
                                        }, key: i },
                                        g.name,
                                        " | Users"));
                                }))))) : (""),
                    view == "update" ? (React.createElement(ProjectSettingsOverview, { updateProject: this.props.updateProject, project: {
                            name: this.props.name,
                            id: this.props.project.id,
                            thumbnail: this.props.project.thumbnail,
                        } })) : ("")))));
    };
    return ProjectSettingsModal;
}(React.Component));
var IdeaPreview = /** @class */ (function (_super) {
    __extends(IdeaPreview, _super);
    function IdeaPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IdeaPreview.prototype.render = function () {
        var _a = this.props, idea = _a.idea, loadIdea = _a.loadIdea;
        var thumbCheck = parseInt(idea.thumbnail);
        if (!isNaN(thumbCheck)) {
            // numerical (default cover img)
            idea.thumbnail = "/images/cover_images/" + idea.thumbnail + ".png";
        }
        return (React.createElement("div", { onClick: function () {
                loadIdea(idea.id);
            }, className: "col-lg-3 col-sm-4 col-xs-6 recent-idea" },
            React.createElement("div", { className: "recent-idea-wrapper" },
                React.createElement("img", { className: "thumbnail", src: idea.thumbnail }),
                React.createElement("h5", { className: "id font-alt" }, idea.id),
                React.createElement("h4", { className: "title" }, idea.title),
                React.createElement("h5", { className: "created" },
                    TimeSince(idea.created),
                    " ago"))));
    };
    return IdeaPreview;
}(React.Component));
var TableIdeaPreview = /** @class */ (function (_super) {
    __extends(TableIdeaPreview, _super);
    function TableIdeaPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TableIdeaPreview.prototype.render = function () {
        var _a = this.props, idea = _a.idea, loadIdea = _a.loadIdea;
        var thumbCheck = parseInt(idea.thumbnail);
        if (!isNaN(thumbCheck)) {
            // numerical (default cover img)
            idea.thumbnail = "/images/cover_images/" + idea.thumbnail + ".png";
        }
        return (React.createElement("tr", null,
            React.createElement("td", { onClick: function () {
                    loadIdea(idea.id);
                } },
                React.createElement("img", { className: "thumbnail", src: idea.thumbnail == "" || idea.thumbnail == null
                        ? "/images/cover_images/1.png"
                        : idea.thumbnail }),
                " "),
            React.createElement("td", { onClick: function () {
                    loadIdea(idea.id);
                } },
                React.createElement("b", null, idea.title)),
            React.createElement("td", { onClick: function () {
                    loadIdea(idea.id);
                }, className: "updated" },
                "Updated ",
                TimeSince(idea.created),
                " ago")));
    };
    return TableIdeaPreview;
}(React.Component));
var ProjectPage = connector(ProjectPageLocal);
export default ProjectPage;
