var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import CreateIdeaForm from "forms/createIdea/createIdeaForm";
import CreateIdeaForUserRoleForm from "forms/createidea-for-user/createIdeaForUserRoleForm";
import TopNav from "nav/top/main";
import React from "react";
import { connect } from "react-redux";
var mapState = function (state) { return ({
    Organization: state.App.Organization,
    User: state.App.User,
    Socket: state.App.Socket,
}); };
var connector = connect(mapState, null);
var CreateIdeaPageLocal = /** @class */ (function (_super) {
    __extends(CreateIdeaPageLocal, _super);
    function CreateIdeaPageLocal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CreateIdeaPageLocal.prototype.render = function () {
        return (React.createElement("div", { className: "create-idea-page" },
            React.createElement("div", { className: "container-fluid" },
                React.createElement(TopNav, null)),
            this.props.User.admin || this.props.User.leader ? (React.createElement(CreateIdeaForm, null)) : (React.createElement(CreateIdeaForUserRoleForm, null))));
    };
    return CreateIdeaPageLocal;
}(React.Component));
var CreateIdeaPage = connector(CreateIdeaPageLocal);
export default CreateIdeaPage;
