var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ActionHandler } from "components/fileHandler/actions";
import { UpdateInput } from "helpers/inputHandlers";
import { FORMDATA_REQUEST_WITH_UPLOAD_PROGRESS, USER_POST_REQUEST, } from "helpers/requests";
import Shuffle from "helpers/shuffle";
import React from "react";
import AddFiles from "./stages/addFiles";
import AddIdea from "./stages/addIdea";
import AssignMetadata from "./stages/assignMetadata";
import CoverImage from "./stages/coverImage";
import ReviewIdea from "./stages/reviewIdea";
import Lottie from "lottie-web";
import { GetQueryParam } from "helpers/queryParams";
import Collaborators from "./stages/collaborators";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
var mapState = function (state) { return ({
    Organization: state.App.Organization,
    User: state.App.User,
    Socket: state.App.Socket,
}); };
var connector = connect(mapState, null);
/* --- */
var CreateIdeaForUserRoleFormLocal = /** @class */ (function (_super) {
    __extends(CreateIdeaForUserRoleFormLocal, _super);
    function CreateIdeaForUserRoleFormLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.addCollaboratingGroup = function (id) {
            console.log(id);
            var CollaboratingGroups = _this.state.CollaboratingGroups;
            _this.setState({
                CollaboratingGroups: __spreadArray(__spreadArray([], CollaboratingGroups, true), [id], false),
            });
        };
        _this.removeCollaboratingGroup = function (id) {
            var CollaboratingGroups = _this.state.CollaboratingGroups;
            _this.setState({
                CollaboratingGroups: CollaboratingGroups.filter(function (g) {
                    return g != id;
                }),
            });
        };
        _this.addCollaboratingUser = function (id) {
            var CollaboratingUsers = _this.state.CollaboratingUsers;
            _this.setState({
                CollaboratingUsers: __spreadArray(__spreadArray([], CollaboratingUsers, true), [id], false),
            });
        };
        _this.removeCollaboratingUser = function (id) {
            var CollaboratingUsers = _this.state.CollaboratingUsers;
            _this.setState({
                CollaboratingUsers: CollaboratingUsers.filter(function (u) {
                    return u != id;
                }),
            });
        };
        _this.setCurrentProject = function (project) {
            if (project == null) {
                _this.setState({
                    CurrentProject: project,
                    CurrentProjectID: null,
                }, function () {
                    window.history.pushState({}, document.title, window.location.pathname);
                });
            }
            else {
                USER_POST_REQUEST("get-project-groups-users", { project_id: project.id }, function (res) {
                    _this.setState({
                        CurrentProject: res,
                        CurrentProjectID: project.id,
                    });
                }, function (err) {
                    console.log(err);
                });
                window.history.pushState({}, document.title, "".concat(window.location.pathname, "?project=").concat(project.id));
            }
        };
        _this.updateStage = function (e) {
            _this.state.Stage == 4 || _this.state.Stage == 3
                ? _this.stagesComplete.set(_this.state.Stage, true)
                : "";
            _this.setState({
                Stage: parseInt(e.currentTarget.dataset.stage),
            });
        };
        _this.setStage = function (s) {
            _this.setState({
                Stage: s,
            });
        };
        _this.tagActions = function (action, tag) {
            if (action == "add") {
                if (_this.state.Tags.includes(tag)) {
                    return;
                }
                if (_this.state.Tags.length == 0) {
                    _this.setState({
                        Tags: [tag],
                    });
                }
                else {
                    _this.setState(function (prev) { return ({
                        Tags: __spreadArray([tag], prev.Tags, true),
                    }); });
                }
            }
            if (action == "remove") {
                _this.setState(function (prev) { return ({
                    Tags: prev.Tags.filter(function (t, _) {
                        return t != tag;
                    }),
                }); });
            }
        };
        _this.setCoverImage = function (img) {
            _this.setState({
                Thumbnail: img,
            });
        };
        var randomizedCover = Array.from({ length: 20 }, function (_, index) { return index + 1; });
        Shuffle(randomizedCover);
        var initialCover = randomizedCover[0];
        _this.state = {
            AvailableGroups: [],
            AvailableUsers: [],
            CollaboratingGroups: [],
            CollaboratingUsers: [],
            SubmissionStage: "",
            SubmittingIdea: false,
            Title: "",
            Description: "",
            DescriptionHTML: "",
            Stage: 1,
            files: [],
            folders: [],
            Thumbnail: "".concat(initialCover),
            Visibility: "private",
            CurrentProjectID: GetQueryParam("project"),
            CurrentProject: null,
            RecentProjects: [],
            Tags: [],
            RandomizedCover: randomizedCover,
            UserIdeasCount: 0,
        };
        _this.stagesComplete = new Map();
        _this.updateInput = UpdateInput.bind(_this);
        _this.updateStage = _this.updateStage.bind(_this);
        _this.nextStep = _this.nextStep.bind(_this);
        _this.previousStep = _this.previousStep.bind(_this);
        _this.setStage = _this.setStage.bind(_this);
        _this.cancel = _this.cancel.bind(_this);
        // Files specific
        _this.actionHandler = ActionHandler.bind(_this);
        // Metadata specific
        _this.tagActions = _this.tagActions.bind(_this);
        _this.setCurrentProject = _this.setCurrentProject.bind(_this);
        _this.setVisibility = _this.setVisibility.bind(_this);
        _this.addCollaboratingGroup = _this.addCollaboratingGroup.bind(_this);
        _this.addCollaboratingUser = _this.addCollaboratingUser.bind(_this);
        //Thumb specific
        _this.setCoverImage = _this.setCoverImage.bind(_this);
        //
        _this.submitIdea = _this.submitIdea.bind(_this);
        return _this;
    }
    CreateIdeaForUserRoleFormLocal.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST("get-user-ideas-count", {}, function (res) {
            _this.setState({
                UserIdeasCount: res.user_ideas || 0,
            });
        }, function (err) {
            console.log(err);
        });
        // pull recent projects
        USER_POST_REQUEST("get-metadata-for-idea-creation", { project_id: this.state.CurrentProjectID }, function (res) {
            if (res.current_project != null) {
                if (res.current_project.id != "") {
                    _this.setState({
                        CurrentProject: res.current_project,
                    });
                }
            }
            _this.setState({
                RecentProjects: res.recent_projects || [],
                AvailableGroups: _this.props.Organization.groups || [],
                AvailableUsers: _this.props.Organization.users || [],
            });
        }, function (err) {
            console.log(err);
        });
    };
    CreateIdeaForUserRoleFormLocal.prototype.nextStep = function () {
        this.state.Stage == 4 || this.state.Stage == 3
            ? this.stagesComplete.set(this.state.Stage, true)
            : "";
        this.setState(function (prev) { return ({
            Stage: prev.Stage + 1,
        }); });
    };
    CreateIdeaForUserRoleFormLocal.prototype.previousStep = function () {
        this.setState(function (prev) { return ({
            Stage: prev.Stage - 1,
        }); });
    };
    CreateIdeaForUserRoleFormLocal.prototype.cancel = function () {
        location.href = "/";
    };
    CreateIdeaForUserRoleFormLocal.prototype.handleFolder = function (folder, fD, parentTree) {
        var _this = this;
        var folderName = "".concat(parentTree != undefined ? parentTree + "/" : "").concat(folder.name);
        fD.append("folders[]", folderName);
        folder.files.forEach(function (file) {
            fD.append("".concat(folderName, "-files[]"), file.data);
        });
        folder.folders.forEach(function (folder) {
            fD = _this.handleFolder(folder, fD, folderName);
        });
        return fD;
    };
    CreateIdeaForUserRoleFormLocal.prototype.setVisibility = function (vis) {
        this.setState({
            Visibility: vis,
        });
    };
    CreateIdeaForUserRoleFormLocal.prototype.submitIdea = function () {
        var _this = this;
        document.body.classList.add("modal-open");
        var anim = Lottie.loadAnimation({
            container: document.getElementById("ideablockIdeaLoader"),
            renderer: "svg",
            loop: false,
            autoplay: false,
            path: "/anim/loader.json",
        });
        this.setState({
            SubmittingIdea: true,
            SubmissionStage: "Verifying Idea Data",
        }, function () {
            setTimeout(function () {
                anim.play();
                setTimeout(function () {
                    _this.setState({
                        SubmissionStage: "Uploading Idea Files",
                    });
                    return;
                }, 500);
                var fD = new FormData();
                fD.append("title", _this.state.Title);
                fD.append("description", _this.state.Description);
                if (_this.state.CurrentProjectID != null &&
                    _this.state.CurrentProjectID != "") {
                    fD.append("project", _this.state.CurrentProjectID);
                }
                _this.state.Tags.forEach(function (tag) {
                    fD.append("tags[]", tag);
                });
                fD.append("thumbnail", _this.state.Thumbnail);
                var _a = _this.state, files = _a.files, folders = _a.folders, CollaboratingGroups = _a.CollaboratingGroups, CollaboratingUsers = _a.CollaboratingUsers;
                files.forEach(function (file) {
                    fD.append("files[]", file.data);
                });
                folders.forEach(function (folder) {
                    fD = _this.handleFolder(folder, fD);
                });
                CollaboratingGroups.forEach(function (group) {
                    fD.append("collaborating_groups[]", group);
                });
                CollaboratingUsers.forEach(function (user) {
                    fD.append("collaborating_users[]", user);
                });
                fD.append("visibility", _this.state.Visibility);
                anim.pause();
                FORMDATA_REQUEST_WITH_UPLOAD_PROGRESS("create-idea", fD, function (res) {
                    _this.setState({
                        SubmissionStage: "Finalizing Idea",
                    });
                    anim.play();
                    anim.setSpeed(1);
                    anim.addEventListener("complete", function () {
                        _this.setState({
                            SubmissionStage: "Redirecting you to your idea...",
                        }, function () {
                            setTimeout(function () {
                                document.body.classList.remove("modal-open");
                                location.href = "/idea/" + res.idea;
                            }, 250);
                        });
                    });
                }, function (uploadProgress) {
                    anim.goToAndStop(Math.round((uploadProgress.loaded / uploadProgress.total) * 2000));
                    _this.setState({
                        SubmissionStage: "Uploading Files ".concat(Math.round((uploadProgress.loaded / uploadProgress.total) * 100), "%"),
                    });
                }, function (err) {
                    console.log(err);
                });
            }, 1000);
        });
    };
    CreateIdeaForUserRoleFormLocal.prototype.render = function () {
        var _a, _b;
        var _c = this.state, Stage = _c.Stage, SubmittingIdea = _c.SubmittingIdea, SubmissionStage = _c.SubmissionStage, UserIdeasCount = _c.UserIdeasCount;
        var data = this.state;
        // this.stagesComplete.set(5, true)
        data.Description.length > 0 || data.Title.length > 0
            ? this.stagesComplete.set(1, true)
            : "";
        data.files.length > 0 || data.folders.length > 0
            ? this.stagesComplete.set(2, true)
            : "";
        // @ts-ignore
        if ("test" == "TRIAL") {
            if (UserIdeasCount >= 500) {
                return (React.createElement("div", { className: "create-idea-form" },
                    React.createElement("div", { className: "stage-wrapper container-fluid" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "container" },
                                React.createElement("div", { className: "row align-items-center" },
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("h1", { className: "font-alt-2 t-center" }, "IDEABLOCK TRIAL")))))),
                    React.createElement("div", { className: "container-fluid" },
                        React.createElement("div", { className: "row content-wrapper" },
                            React.createElement("div", { className: "container" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12 t-center" },
                                        React.createElement("h1", { className: "font-alt", style: { fontWeight: 100 } },
                                            "You have exceeded the maximum number of available ideas for our trial.",
                                            " "),
                                        React.createElement("h3", { className: "font-alt", style: { fontWeight: 100 } },
                                            "Please Contact us @",
                                            " ",
                                            React.createElement("a", { href: "mailto:sales@ideablock.com", style: { color: "var(--yellow-green)" } }, "sales@ideablock.com"),
                                            " ",
                                            "to apply to join our enterprise version.")))))),
                    React.createElement("div", { className: "content-lower container-fluid" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "container" },
                                React.createElement("div", { className: "row justify-content-end align-items-center button-wrapper" }))))));
            }
        }
        if (((_a = this.props.Organization) === null || _a === void 0 ? void 0 : _a.payment_verified) == false ||
            ((_b = this.props.Organization) === null || _b === void 0 ? void 0 : _b.subscription_active) == false) {
            return (React.createElement("div", { className: "create-idea-form" },
                React.createElement("div", { className: "stage-wrapper container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row align-items-center" },
                                React.createElement("div", { className: "col-12" },
                                    React.createElement("h1", { className: "font-alt-2 font-light t-center" }, "IDEABLOCK")))))),
                React.createElement("div", { className: "container-fluid" },
                    React.createElement("div", { className: "row content-wrapper", style: { minHeight: "calc(100vh - 326px)" } },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-12 t-center" },
                                    React.createElement("h1", { className: "font-alt", style: { fontWeight: 100 } },
                                        "Your organization doesn't have an active subscription",
                                        " "),
                                    React.createElement("h3", { className: "font-alt", style: { fontWeight: 100 } }, "Please update organization billing information."),
                                    React.createElement("h3", { style: { marginTop: "15px" } },
                                        React.createElement(Link, { to: "/dashboard", style: {
                                                textDecoration: "none",
                                                color: "var(--yellow-green)",
                                            } }, "Back to Dashboard"))))))),
                React.createElement("div", { className: "content-lower container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row justify-content-end align-items-center button-wrapper" }))))));
        }
        if (this.props.User.seat == "") {
            return (React.createElement("div", { className: "create-idea-form" },
                React.createElement("div", { className: "stage-wrapper container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row align-items-center" },
                                React.createElement("div", { className: "col-12" },
                                    React.createElement("h1", { className: "font-alt-2 font-light t-center" }, "IDEABLOCK")))))),
                React.createElement("div", { className: "container-fluid" },
                    React.createElement("div", { className: "row content-wrapper", style: { minHeight: "calc(100vh - 326px)" } },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-12 t-center" },
                                    React.createElement("h1", { className: "font-alt", style: { fontWeight: 100 } },
                                        "Your account is not currently assigned a seat",
                                        " "),
                                    React.createElement("h3", { className: "font-alt", style: { fontWeight: 100 } }, "Please Contact your organization leader for seat assignment."),
                                    React.createElement("h3", { style: { marginTop: "15px" } },
                                        React.createElement(Link, { to: "/dashboard", style: {
                                                textDecoration: "none",
                                                color: "var(--yellow-green)",
                                            } }, "Back to Dashboard"))))))),
                React.createElement("div", { className: "content-lower container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row justify-content-end align-items-center button-wrapper" }))))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "create-idea-form" },
                React.createElement("div", { className: "stage-wrapper container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row align-items-center" },
                                React.createElement("div", { className: "col-6 stage-left" },
                                    React.createElement("h1", { className: "font-alt font-light" }, Stage == 1
                                        ? "Add an Idea"
                                        : Stage == 2
                                            ? "Add supporting files"
                                            : Stage == 3
                                                ? "Assign metadata"
                                                : Stage == 4
                                                    ? "Add a cover image"
                                                    : Stage == 5
                                                        ? "Add Collaborators"
                                                        : "Review idea")),
                                React.createElement("div", { className: "col-6 stage-right" },
                                    React.createElement("span", { "data-stage": 1, onClick: this.updateStage, className: "stage-number h-c ".concat(Stage == 1
                                            ? "active"
                                            : this.stagesComplete.get(1) == true
                                                ? "completed"
                                                : "") },
                                        React.createElement("span", { className: "number" }, "1"),
                                        React.createElement("i", { className: "icon-check" })),
                                    React.createElement("span", { className: "stage-separator" }),
                                    React.createElement("span", { "data-stage": 2, onClick: this.updateStage, className: "stage-number h-c ".concat(Stage == 2
                                            ? "active"
                                            : this.stagesComplete.get(2) == true
                                                ? "completed"
                                                : "") },
                                        React.createElement("span", { className: "number" }, "2"),
                                        React.createElement("i", { className: "icon-check" })),
                                    React.createElement("span", { className: "stage-separator" }),
                                    React.createElement("span", { "data-stage": 3, onClick: this.updateStage, className: "stage-number h-c ".concat(Stage == 3
                                            ? "active"
                                            : this.stagesComplete.get(3) == true
                                                ? "completed"
                                                : "") },
                                        React.createElement("span", { className: "number" }, "3"),
                                        React.createElement("i", { className: "icon-check" })),
                                    React.createElement("span", { className: "stage-separator" }),
                                    React.createElement("span", { "data-stage": 4, onClick: this.updateStage, className: "stage-number h-c ".concat(Stage == 4
                                            ? "active"
                                            : this.stagesComplete.get(4) == true
                                                ? "completed"
                                                : "") },
                                        React.createElement("span", { className: "number" }, "4"),
                                        React.createElement("i", { className: "icon-check" })),
                                    React.createElement("span", { className: "stage-separator" }),
                                    React.createElement("span", { "data-stage": 5, onClick: this.updateStage, className: "stage-number h-c ".concat(Stage == 5
                                            ? "active"
                                            : this.stagesComplete.get(5) == true
                                                ? "completed"
                                                : "") },
                                        React.createElement("span", { className: "number" }, "5"),
                                        React.createElement("i", { className: "icon-check" })),
                                    React.createElement("span", { className: "stage-separator" }),
                                    React.createElement("span", { "data-stage": 6, onClick: this.updateStage, className: "stage-number h-c ".concat(Stage == 6
                                            ? "active"
                                            : this.stagesComplete.get(6) == true
                                                ? "completed"
                                                : "") },
                                        React.createElement("span", { className: "number" }, "6"),
                                        React.createElement("i", { className: "icon-check" })),
                                    React.createElement("span", { className: "cancel h-c", onClick: this.cancel },
                                        "Cancel ",
                                        React.createElement("i", { className: "icon-cancel" }))))))),
                React.createElement("div", { className: "content-wrapper container-fluid create-idea-stage-".concat(Stage) },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "container" }, Stage == 1 ? (React.createElement(AddIdea, { data: data, updateInput: this.updateInput })) : Stage == 2 ? (React.createElement(AddFiles, { actionHandler: this.actionHandler, files: data.files, folders: data.folders })) : Stage == 3 ? (React.createElement(AssignMetadata, { setVisibility: this.setVisibility, setCurrentProject: this.setCurrentProject, tagActions: this.tagActions, data: data })) : Stage == 4 ? (React.createElement(CoverImage, { data: data, setCoverImage: this.setCoverImage })) : Stage == 5 ? (React.createElement(Collaborators, { setVisibility: this.setVisibility, addGroup: this.addCollaboratingGroup, addUser: this.addCollaboratingUser, removeGroup: this.removeCollaboratingGroup, removeUser: this.removeCollaboratingUser, data: data })) : Stage == 6 ? (React.createElement(ReviewIdea, { setStage: this.setStage, data: data })) : null))),
                React.createElement("div", { className: "content-lower container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row justify-content-end align-items-center button-wrapper" },
                                React.createElement("span", { onClick: this.previousStep, className: "".concat(Stage == 1 ? "d-none" : "", " idea-previous-stage h-c") },
                                    React.createElement("i", { className: "icon-previous" }),
                                    " ",
                                    "Previous"),
                                React.createElement("span", { className: "btn-separator" }),
                                Stage == 6 ? (React.createElement("button", { onClick: this.submitIdea, disabled: SubmittingIdea, className: "btn btn-blue font-alt ".concat(SubmittingIdea ? "inactive" : "") }, SubmittingIdea ? ("Submitting...") : (React.createElement(React.Fragment, null,
                                    " Verify ", "&",
                                    " Protect")))) : (React.createElement("button", { onClick: this.nextStep, className: "btn btn-blue font-alt ".concat(SubmittingIdea ? "inactive" : "") }, "Next Step"))))))),
            React.createElement("div", { className: "idea-submit-modal-bg ".concat(SubmittingIdea ? "active" : "") }),
            React.createElement("div", { className: "idea-submit-modal ".concat(SubmittingIdea ? "active" : "") },
                React.createElement("div", { id: "ideablockIdeaLoader" }),
                React.createElement("h1", { className: "font-alt" }, "Submitting your idea for protection"),
                React.createElement("h3", null, SubmissionStage))));
    };
    return CreateIdeaForUserRoleFormLocal;
}(React.Component));
var CreateIdeaForUserRoleForm = connector(CreateIdeaForUserRoleFormLocal);
export default CreateIdeaForUserRoleForm;
