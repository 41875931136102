var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState, useRef } from "react";
import Dashboard from "../../templates/dashboard";
import { USER_POST_REQUEST } from "../../helpers/requests";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "universal-cookie";
export var BillingPage = function () {
    var _a = useState([]), payment_methods = _a[0], setPaymentMethods = _a[1];
    var _b = useState(""), selectedPaymentMethod = _b[0], setSelectedPaymentMethod = _b[1];
    var _c = useState(false), loaded = _c[0], setLoaded = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(0), userSeats = _e[0], setUserSeats = _e[1];
    var _f = useState(1), adminSeats = _f[0], setAdminSeats = _f[1];
    var _g = useState(1), dbAdminSeats = _g[0], setDbAdminSeats = _g[1];
    var _h = useState(0), dbUserSeats = _h[0], setDbUserSeats = _h[1];
    var _j = useState(false), showAddPaymentMethod = _j[0], setShowAddPaymentMethod = _j[1];
    var _k = useState(false), proccessingPaymentIntent = _k[0], setproccessingPaymentIntent = _k[1];
    var _l = useState(null), paymentMethodError = _l[0], setPaymentMethodError = _l[1];
    var _m = useState(null), paymentMethodSuccess = _m[0], setPaymentMethodSuccess = _m[1];
    var _o = useState(""), clientSecret = _o[0], setClientSecret = _o[1];
    var _p = useState(""), stripeCustomerId = _p[0], setStripeCustomerId = _p[1];
    var _q = useState([]), products = _q[0], setProducts = _q[1];
    var _r = useState({}), quantities = _r[0], setQuantities = _r[1];
    var cardElementRef = useRef(null);
    var paymentCardElementRef = useRef(null);
    var _s = useState(null), stripe = _s[0], setStripe = _s[1];
    var _t = useState(null), cardElement = _t[0], setCardElement = _t[1];
    var _u = useState(null), paymentCardElement = _u[0], setPaymentCardElement = _u[1];
    var _v = useState(null), error = _v[0], setError = _v[1];
    var _w = useState(false), processing = _w[0], setProcessing = _w[1];
    var _x = useState(false), paymentAddProcceing = _x[0], setPaymentAddProcceing = _x[1];
    var _y = useState(false), showPaymentForm = _y[0], setShowPaymentForm = _y[1];
    var _z = useState(null), success = _z[0], setSuccess = _z[1];
    var _0 = useState(null), successRemovingPaymentMethod = _0[0], setSuccessRemovingPaymentMethod = _0[1];
    var _1 = useState(null), errorRemovingPaymentMethod = _1[0], setErrorRemovingPaymentMethod = _1[1];
    var _2 = useState({}), initialQuantities = _2[0], setInitialQuantities = _2[1];
    var initializeStripe = function () { return __awaiter(void 0, void 0, void 0, function () {
        var stripePromise, stripeInstance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    stripePromise = loadStripe(
                    // "pk_test_51QSevF2Y7DlXY3qYbBKocDnUEyUme2aMcEJ8KYYisu3RnORWWDpenwPKfjC1r5WsCbpIENrExCFq1NQSuDGTonFz00kkp2bC7k"
                    "pk_test_51QUDfmQotevYImKaVdCRiLT1ujfkwBrAHJoSNMCbOF5f6mJSEEYzFUZUS9oFcyaCPczhzekLpoRcHB2ixILj9DMz000vuC713z");
                    return [4 /*yield*/, stripePromise];
                case 1:
                    stripeInstance = _a.sent();
                    setStripe(stripeInstance);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleRemovePaymentMethod = function (paymentMethodId) {
        USER_POST_REQUEST("remove-payment-method", { payment_method_id: paymentMethodId }, function (res) {
            setPaymentMethods(payment_methods.filter(function (pm) { return pm.id !== paymentMethodId; }));
            setSuccessRemovingPaymentMethod("Payment method removed successfully");
        }, function (err) {
            setError(err.message);
            setErrorRemovingPaymentMethod("An unexpected error occurred");
        });
    };
    var getInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            USER_POST_REQUEST("get-organization-billing-info", {}, function (res) {
                var adminSeatsCount = Math.max(1, res.admin_seats || 1);
                setAdminSeats(adminSeatsCount);
                setUserSeats(res.user_seats || 0);
                setDbAdminSeats(adminSeatsCount);
                setDbUserSeats(res.user_seats || 0);
                setPaymentMethods(res.payment_methods || []);
                setLoaded(true);
                setClientSecret(res.client_secret);
                setStripeCustomerId(res.stripe_id);
            }, function (err) {
                console.log(err);
            });
            return [2 /*return*/];
        });
    }); };
    var fetchProducts = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                setLoading(true);
                USER_POST_REQUEST("get-stripe-products", {}, function (res) {
                    var productsData = res;
                    var initialQuantities = {};
                    productsData.forEach(function (product) {
                        var productNameLower = product.name.toLowerCase();
                        if (productNameLower.includes("admin")) {
                            initialQuantities[product.id] = Math.max(1, adminSeats);
                        }
                        else if (productNameLower.includes("user")) {
                            initialQuantities[product.id] = userSeats;
                        }
                        else {
                            initialQuantities[product.id] = 0;
                        }
                    });
                    setProducts(productsData);
                    setQuantities(initialQuantities);
                    setInitialQuantities(initialQuantities); // Store initial quantities
                }, function (err) {
                    console.log(err);
                });
            }
            catch (error) {
                console.log(error);
            }
            finally {
                setLoading(false);
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        setLoading(true);
        getInfo();
        initializeStripe();
        return function () {
            if (cardElement) {
                cardElement.destroy();
            }
            if (paymentCardElement) {
                paymentCardElement.destroy();
            }
            setLoading(false);
        };
    }, []);
    useEffect(function () {
        fetchProducts();
    }, [adminSeats, userSeats]);
    useEffect(function () {
        if (stripe &&
            showPaymentForm &&
            cardElementRef.current &&
            !selectedPaymentMethod) {
            var elements = stripe.elements();
            var cookies = new Cookies();
            var theme = cookies.get("theme");
            if (theme === undefined) {
                theme = "dark";
            }
            var card_1 = elements.create("card", {
                style: {
                    base: {
                        color: theme === "dark" ? "#fff" : "#000",
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                            color: theme === "dark" ? "#fff" : "#000",
                        },
                    },
                    invalid: {
                        color: "#fa755a",
                        iconColor: "#fa755a",
                    },
                },
            });
            card_1.mount(cardElementRef.current);
            setCardElement(card_1);
            return function () {
                card_1.unmount();
            };
        }
    }, [stripe, showPaymentForm, selectedPaymentMethod]);
    useEffect(function () {
        if (stripe && showAddPaymentMethod && paymentCardElementRef.current) {
            var elements = stripe.elements();
            var cookies = new Cookies();
            var theme = cookies.get("theme");
            if (theme === undefined) {
                theme = "dark";
                cookies.set("theme", "dark", { path: "/" });
            }
            var card_2 = elements.create("card", {
                style: {
                    base: {
                        color: theme === "dark" ? "#fff" : "#000",
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                            color: theme === "dark" ? "#fff" : "#000",
                        },
                    },
                    invalid: {
                        color: "#fa755a",
                        iconColor: "#fa755a",
                    },
                },
            });
            card_2.mount(paymentCardElementRef.current);
            setPaymentCardElement(card_2);
            return function () {
                card_2.unmount();
            };
        }
    }, [stripe, showAddPaymentMethod]);
    var updatePaymentMethod = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, setupIntent, error_1, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!stripe || !paymentCardElement) {
                        setPaymentMethodError("Payment initialization failed");
                        return [2 /*return*/];
                    }
                    setPaymentAddProcceing(true);
                    setPaymentMethodError(null);
                    setPaymentMethodSuccess(null);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 5, 6, 7]);
                    return [4 /*yield*/, stripe.confirmCardSetup(clientSecret, {
                            payment_method: {
                                card: paymentCardElement,
                                billing_details: {},
                            },
                        })];
                case 2:
                    _a = _b.sent(), setupIntent = _a.setupIntent, error_1 = _a.error;
                    if (error_1) {
                        setPaymentMethodError(error_1.message);
                        return [2 /*return*/];
                    }
                    if (!(setupIntent.status === "succeeded")) return [3 /*break*/, 4];
                    setPaymentMethodSuccess("Payment method saved successfully");
                    console.log("Payment method ID:", setupIntent.payment_method);
                    return [4 /*yield*/, getInfo()];
                case 3:
                    _b.sent();
                    setShowAddPaymentMethod(false);
                    _b.label = 4;
                case 4: return [3 /*break*/, 7];
                case 5:
                    err_1 = _b.sent();
                    setPaymentMethodError(err_1.message || "An unexpected error occurred");
                    return [3 /*break*/, 7];
                case 6:
                    setPaymentAddProcceing(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var updateQuantity = function (productId, value) {
        setQuantities(function (prev) {
            var newQuantities = __assign({}, prev);
            var product = products.find(function (p) { return p.id === productId; });
            var isAdmin = product === null || product === void 0 ? void 0 : product.name.toLowerCase().includes("admin");
            if (isAdmin) {
                newQuantities[productId] = Math.max(1, value);
            }
            else {
                newQuantities[productId] = Math.max(0, value);
            }
            return newQuantities;
        });
    };
    var handleGetPaymentIntent = function () { return __awaiter(void 0, void 0, void 0, function () {
        var items_1, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setproccessingPaymentIntent(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    items_1 = Object.entries(quantities)
                        .filter(function (_a) {
                        var _ = _a[0], quantity = _a[1];
                        return quantity > 0;
                    })
                        .map(function (_a) {
                        var productId = _a[0], quantity = _a[1];
                        return ({
                            productId: productId,
                            quantity: parseInt(quantity.toString()),
                        });
                    });
                    if (payment_methods.length > 0) {
                        setSelectedPaymentMethod(payment_methods[0].id);
                    }
                    // Convert callback-based function to Promise
                    return [4 /*yield*/, new Promise(function (resolve, reject) {
                            USER_POST_REQUEST("create-checkout-session", __assign({ items: items_1 }, (selectedPaymentMethod && {
                                payment_method_id: selectedPaymentMethod,
                            })), function (res) {
                                setClientSecret(res.clientSecret);
                                setShowPaymentForm(true);
                                resolve(res);
                            }, function (err) {
                                console.error(err);
                                setError(err.message);
                                reject(err);
                            });
                        })];
                case 2:
                    // Convert callback-based function to Promise
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    err_2 = _a.sent();
                    console.error("Payment intent error:", err_2);
                    setError(err_2.message);
                    return [3 /*break*/, 5];
                case 4:
                    setproccessingPaymentIntent(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handlePayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var paymentMethodDetails, _a, confirmError, paymentIntent, admin_seats_1, user_seats_1, err_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!stripe || (!cardElement && !selectedPaymentMethod) || !clientSecret) {
                        setError("Please select a payment method or enter card details");
                        return [2 /*return*/];
                    }
                    setProcessing(true);
                    setError(null);
                    setSuccess(null);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 5, 6, 7]);
                    if (!(selectedPaymentMethod != null && selectedPaymentMethod !== "")) return [3 /*break*/, 3];
                    return [4 /*yield*/, handleGetPaymentIntent()];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    paymentMethodDetails = selectedPaymentMethod
                        ? { payment_method: selectedPaymentMethod }
                        : { payment_method: { card: cardElement, billing_details: {} } };
                    return [4 /*yield*/, stripe.confirmCardPayment(clientSecret, paymentMethodDetails)];
                case 4:
                    _a = _b.sent(), confirmError = _a.error, paymentIntent = _a.paymentIntent;
                    if (confirmError) {
                        setError(confirmError.message);
                        return [2 /*return*/];
                    }
                    setSuccess("Payment successful!");
                    admin_seats_1 = 0;
                    user_seats_1 = 0;
                    Object.entries(quantities).forEach(function (_a) {
                        var productId = _a[0], quantity = _a[1];
                        var product = products.find(function (p) { return p.id === productId; });
                        if (product) {
                            if (product.name.toLowerCase().includes("admin")) {
                                admin_seats_1 = Math.max(1, admin_seats_1 + quantity);
                            }
                            else if (product.name.toLowerCase().includes("user")) {
                                user_seats_1 += quantity;
                            }
                        }
                    });
                    USER_POST_REQUEST("verify-payment", { payment_intent_id: paymentIntent.id, admin_seats: admin_seats_1, user_seats: user_seats_1 }, function (res) { }, function (err) {
                        setError(err.message);
                    });
                    return [3 /*break*/, 7];
                case 5:
                    err_3 = _b.sent();
                    setError(err_3.message);
                    return [3 /*break*/, 7];
                case 6:
                    setProcessing(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    if (!loaded) {
        return (React.createElement(Dashboard, null,
            React.createElement("div", { className: "font-alt flex items-center justify-center min-h-screen" },
                React.createElement("div", { className: "font-alt text-center" },
                    React.createElement("div", { className: "font-alt animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto" }),
                    React.createElement("p", { className: "font-alt mt-4" }, "Loading...")))));
    }
    var calculateTotalAmount = function () {
        return Object.entries(quantities).reduce(function (total, _a) {
            var productId = _a[0], quantity = _a[1];
            var product = products.find(function (p) { return p.id === productId; });
            return total + (product ? (product.price * quantity) / 100 : 0);
        }, 0);
    };
    var totalAmount = calculateTotalAmount();
    var hasPlanChanged = function () {
        return Object.entries(quantities).some(function (_a) {
            var productId = _a[0], quantity = _a[1];
            return quantity !== (initialQuantities[productId] || 0);
        });
    };
    return (React.createElement(Dashboard, null,
        React.createElement("div", { className: "font-alt  mx-auto " },
            React.createElement("div", { className: "font-alt bg-[#2F3744] py-8 mb-6" },
                React.createElement("div", { className: "font-alt max-w-7xl mx-auto" },
                    React.createElement("h1", { className: "font-alt text-white text-3xl font-bold" }, "Billing"))),
            React.createElement("div", { className: "font-alt max-w-7xl mx-auto" },
                React.createElement("div", { className: "font-alt mb-8" },
                    React.createElement("h2", { className: "font-alt text-2xl font-bold mb-4" }, "Your Current Plan"),
                    React.createElement("div", { className: "font-alt grid grid-cols-1 md:grid-cols-2 gap-4" }, products.map(function (product) { return (React.createElement("div", { key: product.id, className: "font-alt border rounded-lg p-4" },
                        React.createElement("h3", { className: "font-alt capitalize text-xl font-semibold" }, product.name),
                        React.createElement("p", { className: "font-alt " }, product.description),
                        React.createElement("p", { className: "font-alt text-lg font-bold mt-2" },
                            "$",
                            product.price / 100),
                        React.createElement("input", { type: "number", value: quantities[product.id] || 0, onChange: function (e) {
                                return updateQuantity(product.id, parseInt(e.target.value));
                            }, min: product.name.toLowerCase().includes("admin") ? "1" : "0", className: "font-alt w-full my-2.5 p-3 border border-solid rounded-md bg-transparent mt-3 block" }))); })),
                    React.createElement("div", { className: "font-alt text-right text-xl font-bold mt-4" },
                        "Total Amount: $",
                        totalAmount.toFixed(2)),
                    !showPaymentForm && hasPlanChanged() && (React.createElement("div", { className: "font-alt mt-4" },
                        React.createElement("button", { onClick: handleGetPaymentIntent, className: "font-alt w-full bg-[#3B82F6] py-2 px-4 rounded-md font-semibold disabled:opacity-50", disabled: proccessingPaymentIntent || !stripe }, proccessingPaymentIntent ? (React.createElement("div", { className: "font-alt flex items-center justify-center" }, "Processing...")) : ("Upgrade Plan")))),
                    showPaymentForm && (React.createElement("div", { className: "font-alt mt-4 p-6 rounded-lg border border-gray-200" },
                        React.createElement("div", { className: "font-alt flex justify-between items-center mb-4" },
                            React.createElement("h3", { className: "font-alt text-xl font-semibold" }, "Payment Details"),
                            React.createElement("button", { onClick: function () { return setShowPaymentForm(false); }, className: "font-alt text-gray-500 hover:text-gray-700" }, "\u2715")),
                        payment_methods.length > 0 && (React.createElement("div", { className: "font-alt mb-4" },
                            React.createElement("label", { className: "font-alt block mb-2" }, "Select Payment Method:"),
                            React.createElement("select", { value: selectedPaymentMethod, onChange: function (e) { return setSelectedPaymentMethod(e.target.value); }, className: "font-alt w-full text-black p-2 border rounded " },
                                React.createElement("option", { value: "" }, "Add new card"),
                                payment_methods.map(function (pm) { return (React.createElement("option", { className: "font-alt capitalize", key: pm.id, value: pm.id },
                                    pm.brand,
                                    " ending in ",
                                    pm.last_4)); })))),
                        !selectedPaymentMethod && (React.createElement("div", { className: "font-alt p-3 border border-gray-200 roundeloadPaymentIntentd-md" },
                            React.createElement("div", { ref: cardElementRef, className: "font-alt min-h-[40px]" }))),
                        React.createElement("button", { onClick: handlePayment, disabled: processing ||
                                !stripe ||
                                (!cardElement && !selectedPaymentMethod), className: "font-alt w-full mt-4 bg-[#3B82F6]  py-2 px-4 rounded-md font-semibold disabled:opacity-50" }, processing ? "Processing..." : "Complete Payment"),
                        error && (React.createElement("div", { className: "font-alt mt-4 p-3 border border-red-500 text-red-700 rounded" }, error)),
                        success && (React.createElement("div", { className: "font-alt mt-4 p-3 border border-green-500 text-green-700 rounded" }, success)))),
                    React.createElement("div", { className: "font-alt mb-8 mt-3" },
                        React.createElement("h2", { className: "font-alt text-2xl font-bold mb-4" }, "Payment Methods"),
                        React.createElement("div", { className: "font-alt mb-4" },
                            payment_methods.map(function (pm) { return (React.createElement("div", { key: pm.id, className: "font-alt flex justify-between items-center" },
                                React.createElement(PaymentMethodView, { card: pm }),
                                React.createElement("button", { onClick: function () { return handleRemovePaymentMethod(pm.id); }, className: "font-alt text-red-500 hover:text-red-700 ml-4" }, "Remove"))); }),
                            errorRemovingPaymentMethod && (React.createElement("div", { className: "font-alt text-red-500 mt-2" }, errorRemovingPaymentMethod)),
                            successRemovingPaymentMethod && (React.createElement("div", { className: "font-alt text-green-500 mt-2" }, successRemovingPaymentMethod))),
                        !showAddPaymentMethod ? (React.createElement("button", { onClick: function () { return setShowAddPaymentMethod(true); }, className: "font-alt bg-[#3B82F6] text-white px-4 py-2 rounded hover:bg-blue-600" }, "Add New Payment Method")) : (React.createElement("div", { className: "font-alt border rounded-lg p-4" },
                            React.createElement("div", { className: "font-alt flex justify-between items-center mb-4" },
                                React.createElement("h3", { className: "font-alt btn btn-blue text-xl font-semibold" }, "Add New Payment Method"),
                                React.createElement("button", { onClick: function () { return setShowAddPaymentMethod(false); }, className: "font-alt text-gray-500 hover:text-gray-700" }, "\u2715")),
                            React.createElement("div", { ref: paymentCardElementRef, className: "font-alt border rounded p-3 mb-4" }),
                            React.createElement("button", { onClick: updatePaymentMethod, disabled: paymentAddProcceing, className: "font-alt bg-[#3B82F6] text-white px-4 py-2 rounded disabled:bg-gray-400" }, paymentAddProcceing ? "Processing..." : "Add Payment Method"),
                            paymentMethodError && (React.createElement("div", { className: "font-alt text-red-500 mt-2" }, paymentMethodError)),
                            paymentMethodSuccess && (React.createElement("div", { className: "font-alt text-green-500 mt-2" }, paymentMethodSuccess))))))))));
};
var PaymentMethodView = function (_a) {
    var card = _a.card;
    return (React.createElement("div", { className: "font-alt border w-full rounded-lg p-4 mb-2" },
        React.createElement("div", { className: "font-alt font-bold capitalize" }, card.brand),
        React.createElement("div", { className: "font-alt text-gray-600" },
            "XXXX XXXX XXXX ",
            card.last_4),
        React.createElement("div", { className: "font-alt text-sm text-gray-500" },
            card.expiry_month,
            "/",
            card.expiry_year)));
};
