var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { USER_POST_REQUEST } from "helpers/requests";
import React, { useEffect } from "react";
import { useState } from "react";
var VisibilitySettings = function (_a) {
    var ideaId = _a.ideaId, modalOpen = _a.modalOpen, onClose = _a.onClose;
    var _b = useState([]), selectedUsers = _b[0], setSelectedUsers = _b[1];
    var _c = useState(false), showGroupSelector = _c[0], setShowGroupSelector = _c[1];
    var _d = useState(""), searchTerm = _d[0], setSearchTerm = _d[1];
    var _e = useState([]), users = _e[0], setUsers = _e[1];
    var _f = useState([]), groups = _f[0], setGroups = _f[1];
    var _g = useState([]), availableGroups = _g[0], setAvailableGroups = _g[1];
    var _h = useState(""), errorMessage = _h[0], setErrorMessage = _h[1];
    var _j = useState({}), idea = _j[0], setIdea = _j[1];
    var _k = useState([]), selectedGroupIds = _k[0], setSelectedGroupIds = _k[1];
    var _l = useState({
        first_name: "",
        last_name: "",
        email: "",
    }), inviteForm = _l[0], setInviteForm = _l[1];
    var _m = useState(false), showInviteForm = _m[0], setShowInviteForm = _m[1];
    useEffect(function () {
        Promise.all([
            getIdeaPermissions(),
            // getAllGroups(),
            getAllOrganizationMembers(),
            getIdea(),
        ]);
    }, []);
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var permissionsResult, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, getIdeaPermissions()];
                    case 1:
                        permissionsResult = _a.sent();
                        // Then get all groups with the updated groups state
                        return [4 /*yield*/, getAllGroups(permissionsResult)];
                    case 2:
                        // Then get all groups with the updated groups state
                        _a.sent();
                        return [4 /*yield*/, getAllOrganizationMembers()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, getIdea()];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.error("Error fetching data:", error_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, []);
    var getIdeaPermissions = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    USER_POST_REQUEST("get-all-idea-permissions", { idea: ideaId }, function (res) {
                        setUsers(res.users);
                        setGroups(res.groups);
                        resolve(res.groups);
                    }, function (err) {
                        console.error("Error fetching idea permissions:", err);
                        resolve([]);
                    });
                })];
        });
    }); };
    var getAllGroups = function (groupsWithAccess) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    USER_POST_REQUEST("get-groups", {}, function (res) {
                        var availableGroupsFiltered = res.groups.filter(function (group) {
                            return !groupsWithAccess.some(function (g) { return g.hash_id === group.id; });
                        });
                        console.log("Available groups", availableGroupsFiltered);
                        console.log("All groups", res.groups);
                        console.log("Groups with access", groupsWithAccess);
                        setAvailableGroups(availableGroupsFiltered);
                        resolve(res.groups);
                    }, function (err) {
                        console.error("Error fetching groups:", err);
                        resolve([]);
                    });
                })];
        });
    }); };
    var getIdea = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            USER_POST_REQUEST("get-idea", { idea: ideaId }, function (res) {
                setIdea(res);
            }, function (err) {
                console.log(err);
            });
            return [2 /*return*/];
        });
    }); };
    var getAllOrganizationMembers = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            USER_POST_REQUEST("get-organization-members", {}, function (res) {
                // Merge with existing users while avoiding duplicates
                var existingUserIds = new Set(users.map(function (u) { return u.id; }));
                var newUsers = res.filter(function (user) { return !existingUserIds.has(user.id); });
                setUsers(function (prev) { return __spreadArray(__spreadArray([], prev, true), newUsers, true); });
            }, function (err) {
                console.error("Error fetching organization members:", err);
            });
            return [2 /*return*/];
        });
    }); };
    // Update the handleAddUser function
    var handleAddUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var email, first_name, last_name, existingUser;
        return __generator(this, function (_a) {
            email = inviteForm.email, first_name = inviteForm.first_name, last_name = inviteForm.last_name;
            if (!email || !first_name || !last_name) {
                setErrorMessage("All fields are required");
                return [2 /*return*/];
            }
            existingUser = users.find(function (u) { return u.email.toLowerCase() === email.toLowerCase(); });
            if (existingUser) {
                USER_POST_REQUEST("add-user-to-idea", {
                    idea_id: ideaId,
                    user_id: existingUser.id,
                }, function (res) {
                    getIdeaPermissions();
                    setShowInviteForm(false);
                    setInviteForm({ first_name: "", last_name: "", email: "" });
                    setErrorMessage("");
                }, function (err) {
                    setErrorMessage("Failed to add user");
                    console.error("Error adding user:", err);
                });
            }
            else {
                // Invite new user
                USER_POST_REQUEST("invite-user-to-idea", __assign({ idea_id: ideaId }, inviteForm), function (res) { return __awaiter(void 0, void 0, void 0, function () {
                    var permissionsResult;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getIdeaPermissions()];
                            case 1:
                                permissionsResult = _a.sent();
                                return [4 /*yield*/, getAllGroups(permissionsResult)];
                            case 2:
                                _a.sent();
                                setShowInviteForm(false);
                                setInviteForm({ first_name: "", last_name: "", email: "" });
                                setErrorMessage("");
                                return [2 /*return*/];
                        }
                    });
                }); }, function (err) {
                    setErrorMessage("Failed to invite user");
                    console.error("Error inviting user:", err);
                });
            }
            return [2 /*return*/];
        });
    }); };
    // Update the group management functions
    var handleAddGroup = function (groupId) {
        USER_POST_REQUEST("add-group-to-idea", { idea_id: ideaId, group_id: groupId }, function (res) { return __awaiter(void 0, void 0, void 0, function () {
            var permissionsResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getIdeaPermissions()];
                    case 1:
                        permissionsResult = _a.sent();
                        return [4 /*yield*/, getAllGroups(permissionsResult)];
                    case 2:
                        _a.sent();
                        setShowGroupSelector(false);
                        return [2 /*return*/];
                }
            });
        }); }, function (err) {
            console.error("Error adding group:", err);
        });
    };
    var handleRemoveGroup = function (groupId) {
        USER_POST_REQUEST("remove-group-from-idea", { idea_id: ideaId, group_id: groupId }, function (res) { return __awaiter(void 0, void 0, void 0, function () {
            var permissionsResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getIdeaPermissions()];
                    case 1:
                        permissionsResult = _a.sent();
                        return [4 /*yield*/, getAllGroups(permissionsResult)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, function (err) {
            console.error("Error removing group:", err);
        });
    };
    var handleRemoveSelected = function () {
        for (var _i = 0, selectedUsers_1 = selectedUsers; _i < selectedUsers_1.length; _i++) {
            var userId = selectedUsers_1[_i];
            USER_POST_REQUEST("remove-user-from-idea", { idea_id: ideaId, user_id: userId }, function (res) { }, function (err) {
                return;
            });
        }
        getIdeaPermissions();
        setSelectedUsers([]);
    };
    var filteredUsers = users.filter(function (user) {
        return "".concat(user.first_name, " ").concat(user.last_name)
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
    });
    var onAddGroup = function (groupId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            USER_POST_REQUEST("add-group-to-idea", {
                idea_id: ideaId,
                group_id: groupId,
            }, function (res) { return __awaiter(void 0, void 0, void 0, function () {
                var permissionsResult;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getIdeaPermissions()];
                        case 1:
                            permissionsResult = _a.sent();
                            return [4 /*yield*/, getAllGroups(permissionsResult)];
                        case 2:
                            _a.sent();
                            setShowGroupSelector(false);
                            return [2 /*return*/];
                    }
                });
            }); }, function (err) {
                console.error("Error adding group:", err);
            });
            return [2 /*return*/];
        });
    }); };
    var onRemoveGroup = function (groupId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            USER_POST_REQUEST("remove-group-from-idea", {
                idea_id: ideaId,
                group_id: groupId,
            }, function (res) { return __awaiter(void 0, void 0, void 0, function () {
                var permissionsResult;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getIdeaPermissions()];
                        case 1:
                            permissionsResult = _a.sent();
                            return [4 /*yield*/, getAllGroups(permissionsResult)];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, function (err) {
                console.error("Error removing group:", err);
            });
            return [2 /*return*/];
        });
    }); };
    var handleUserSelection = function (userId) {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(function (prev) { return prev.filter(function (id) { return id !== userId; }); });
        }
        else {
            setSelectedUsers(function (prev) { return __spreadArray(__spreadArray([], prev, true), [userId], false); });
        }
    };
    var handleGroupSelection = function (groupId) {
        if (selectedGroupIds.includes(groupId)) {
            setSelectedGroupIds(function (prev) { return prev.filter(function (id) { return id !== groupId; }); });
        }
        else {
            setSelectedGroupIds(function (prev) { return __spreadArray(__spreadArray([], prev, true), [groupId], false); });
        }
    };
    var handleAddSelectedGroups = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _i, selectedGroupIds_1, groupId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _i = 0, selectedGroupIds_1 = selectedGroupIds;
                    _a.label = 1;
                case 1:
                    if (!(_i < selectedGroupIds_1.length)) return [3 /*break*/, 4];
                    groupId = selectedGroupIds_1[_i];
                    return [4 /*yield*/, onAddGroup(groupId)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    setSelectedGroupIds([]);
                    setShowGroupSelector(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "modal idea-visibility-setting ".concat(modalOpen ? "active" : "") },
        React.createElement("div", { className: "modal-content bg-white  container-fluid" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "flex pt-[100px] justify-between items-start mb-6" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "flex flex-col gap-0" },
                            React.createElement("div", { className: " text-[30px] font-alt capitalize font-semibold" }, idea.title),
                            React.createElement("div", { className: " -mt-3 text-[17px] space-mono" },
                                "#",
                                ideaId)),
                        React.createElement("h1", { className: "text-[55px] py-4 font-alt ibm-mono leading-[64px]" }, "VISIBILITY SETTINGS")),
                    React.createElement("button", { onClick: onClose, className: "text-2xl" },
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" },
                            React.createElement("path", { fill: "none", stroke: "currentColor", "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-width": "1.5", d: "m5 19l7-7m0 0l7-7m-7 7L5 5m7 7l7 7" })))),
                React.createElement("div", { className: "grid grid-cols-2 gap-8" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "mb-6" },
                            React.createElement("h2", { className: "text-[24px] ibm-mono mb-2" }, "ADD USERS TO VIEW IDEA"),
                            React.createElement("button", { onClick: function () { return setShowInviteForm(true); }, className: "px-3 py-1 text-sm btn btn-blue mb-2" }, "+ Invite User"),
                            showInviteForm && (React.createElement("div", { className: "fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50" },
                                React.createElement("div", { className: "w-96 user-invitation-form rounded-lg p-6 bg-gray-300 backdrop-blur-sm   shadow-xl" },
                                    React.createElement("h3", { className: "text-lg font-semibold mb-4 text-current" }, "Invite User"),
                                    React.createElement("div", { className: "space-y-4" },
                                        React.createElement("div", null,
                                            React.createElement("label", { className: "block text-sm font-medium mb-1 text-current" }, "First Name"),
                                            React.createElement("input", { type: "text", placeholder: "First Name", value: inviteForm.first_name, onChange: function (e) {
                                                    return setInviteForm(function (prev) { return (__assign(__assign({}, prev), { first_name: e.target.value })); });
                                                }, className: "\n                            w-full\n                            border-gray-600 \n                            rounded px-3 py-2 pr-8 \n                            text-sm\n                            bg-transparent\n                            border\n                            text-current\n                            placeholder-gray-500\n                            outline-none\n                            focus:border-blue-500\n                            transition-colors\n                                " })),
                                        React.createElement("div", null,
                                            React.createElement("label", { className: "block text-sm font-medium mb-1 text-current" }, "Last Name"),
                                            React.createElement("input", { type: "text", placeholder: "Last Name", value: inviteForm.last_name, onChange: function (e) {
                                                    return setInviteForm(function (prev) { return (__assign(__assign({}, prev), { last_name: e.target.value })); });
                                                }, className: "\n                            w-full\n                            \n                                                 border border-gray-600 \n                            rounded px-3 py-2 pr-8 \n                            text-sm\n                            bg-transparent\n                            text-current\n                            placeholder-gray-500\n                            outline-none\n                            focus:border-blue-500\n                            transition-colors\n                              " })),
                                        React.createElement("div", null,
                                            React.createElement("label", { className: "block text-sm font-medium mb-1 text-current" }, "Email"),
                                            React.createElement("input", { type: "email", placeholder: "Email", value: inviteForm.email, onChange: function (e) {
                                                    return setInviteForm(function (prev) { return (__assign(__assign({}, prev), { email: e.target.value })); });
                                                }, className: "\n                            w-full  \n                            border border-gray-600\n                            rounded px-3 py-2 pr-8 \n                            text-sm\n                            bg-transparent\n                            text-current\n                            placeholder-gray-500\n                            outline-none\n                            focus:border-blue-500\n                            transition-colors\n                                " })),
                                        errorMessage && (React.createElement("p", { className: "text-red-500 text-sm" }, errorMessage)),
                                        React.createElement("div", { className: "flex justify-end gap-3 mt-6" },
                                            React.createElement("button", { onClick: function () {
                                                    setShowInviteForm(false);
                                                    setInviteForm({
                                                        first_name: "",
                                                        last_name: "",
                                                        email: "",
                                                    });
                                                    setErrorMessage("");
                                                }, className: "\n                                  btn !py-1\n                                  transition-colors\n                                " }, "Cancel"),
                                            React.createElement("button", { onClick: handleAddUser, className: "\n                                  !py-1 btn btn-blue\n                                  disabled:opacity-50\n                                  disabled:cursor-not-allowed\n                                " }, "Invite"))))))),
                        React.createElement("div", { className: "mb-6" },
                            React.createElement("div", { className: "flex justify-between items-center mb-4" },
                                React.createElement("h2", { className: "text-[24px] ibm-mono" },
                                    "GROUPS WITH ACCESS (",
                                    groups.length,
                                    ")"),
                                React.createElement("button", { onClick: function () { return setShowGroupSelector(true); }, className: "px-3 whitespace-nowrap py-1 text-sm btn btn-blue" }, "+ Add Group")),
                            showGroupSelector && (React.createElement("div", { className: "fixed inset-0 flex items-center justify-center z-50 bg-black/30 backdrop-blur-sm" },
                                React.createElement("div", { className: "w-96 group-selector-modal bg-gray-400 backdrop-blur-sm   rounded-lg p-6 shadow-xl" },
                                    React.createElement("h3", { className: "text-lg font-semibold mb-4 text-current" }, "Add Groups"),
                                    React.createElement("div", { className: "max-h-96 overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400" },
                                        availableGroups.map(function (group) { return (React.createElement("div", { key: group.id, className: "\n                                    flex items-center gap-3 p-2 \n                                    rounded transition-colors duration-200 \n                                    cursor-pointer\n                                    hover:bg-gray-100/50\n                                    ".concat(selectedGroupIds.includes(group.id)
                                                ? "bg-blue-50/50"
                                                : "", "\n                                  ") },
                                            React.createElement("input", { type: "checkbox", checked: selectedGroupIds.includes(group.id), onChange: function () { return handleGroupSelection(group.id); }, className: "\n                                w-4 h-4\n                                rounded\n                                border-gray-300\n                                text-blue-600\n                                focus:ring-blue-500\n                                cursor-pointer\n                              " }),
                                            React.createElement("div", { className: "w-8 h-8 rounded overflow-hidden flex-shrink-0 bg-gray-100" }, group.thumbnail && (React.createElement("img", { src: group.thumbnail, alt: group.name, className: "w-full h-full object-cover rounded" }))),
                                            React.createElement("span", { className: "truncate text-current" }, group.name))); }),
                                        availableGroups.length === 0 && (React.createElement("div", { className: "text-center text-gray-500" }, "No groups available"))),
                                    React.createElement("div", { className: "mt-6 flex justify-end gap-3" },
                                        React.createElement("button", { className: "\n                                btn !py-1\n                                transition-colors\n                              ", onClick: function () {
                                                setShowGroupSelector(false);
                                                setSelectedGroupIds([]);
                                            } }, "Cancel"),
                                        React.createElement("button", { className: "\n                         !py-1 btn btn-blue\n                          disabled:opacity-50\n                          disabled:cursor-not-allowed\n                        ", onClick: handleAddSelectedGroups, disabled: selectedGroupIds.length === 0 },
                                            "Add Selected (",
                                            selectedGroupIds.length,
                                            ")"))))),
                            React.createElement("div", { className: "flex flex-wrap gap-2" }, groups.map(function (group) { return (React.createElement("div", { key: group.id, className: "group-preview w-64 h-64 relative" },
                                React.createElement("div", { className: "bg-white/10 backdrop-blur-sm border border-gray-200 shadow-lg rounded-lg transition-all hover:shadow-xl" },
                                    React.createElement("button", { onClick: function () { return onRemoveGroup(group.id); }, className: "\n                            absolute top-2 right-2 z-10\n                            w-8 h-8\n                            flex items-center justify-center\n                            bg-gray-100 hover:bg-red-100\n                            text-gray-600 hover:text-red-600\n                            rounded-full\n                            transition-all\n                            shadow-sm\n                          " },
                                        React.createElement("svg", { className: "w-5 h-5", fill: "none", stroke: "currentColor", viewBox: "0 0 24 24" },
                                            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M6 18L18 6M6 6l12 12" }))),
                                    React.createElement("div", { className: "relative" },
                                        React.createElement("img", { className: "w-full object-cover h-28 rounded-t-lg", src: group.thumbnail == null || group.thumbnail == ""
                                                ? "/images/cover_images/1.png"
                                                : group.thumbnail, alt: group.name })),
                                    React.createElement("div", { className: "p-4 space-y-2" },
                                        React.createElement("h2", { className: "text-lg font-medium capitalize text-current" }, group.name),
                                        React.createElement("div", { className: "flex items-center text-sm text-gray-500" },
                                            React.createElement("span", { className: "font-semibold mr-1" }, group.user_count || 0),
                                            "USERS"))))); })))),
                    React.createElement("div", null,
                        React.createElement("div", { className: "flex gap-4 items-center justify-between mb-4" },
                            React.createElement("h2", { className: "text-[24px] ibm-mono font-medium text-current" },
                                "USERS WITH ACCESS (",
                                users.length,
                                ")"),
                            React.createElement("div", { className: "flex gap-2" },
                                selectedUsers.length > 0 && (React.createElement("button", { onClick: handleRemoveSelected, className: "text-red-500 text-sm hover:opacity-80 whitespace-nowrap transition-opacity" },
                                    "Remove Selected (",
                                    selectedUsers.length,
                                    ")")),
                                React.createElement("div", { className: "relative" },
                                    React.createElement("input", { type: "text", placeholder: "Search users...", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); }, className: "\n                            border border-gray-300 \n                            rounded px-3 py-2 pr-8 \n                            text-sm\n                            bg-transparent\n                            text-current\n                            placeholder-gray-500\n                            outline-none\n                            focus:border-blue-500\n                            transition-colors\n                          " }),
                                    React.createElement("svg", { className: "w-4 h-4 absolute right-2 top-1/2 -translate-y-1/2 text-gray-500", fill: "currentColor", viewBox: "0 0 20 20" },
                                        React.createElement("path", { d: "M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" }))))),
                        React.createElement("div", { className: "space-y-2 overflow-y-scroll border border-gray-200" }, filteredUsers.map(function (user) { return (React.createElement("div", { key: user.id, className: "flex items-center hover:text-gray-600 gap-3 p-2 rounded transition-colors ".concat(selectedUsers.includes(user.id)
                                ? "bg-blue-100 text-blue-900"
                                : "hover:bg-gray-100 ") },
                            React.createElement("input", { type: "checkbox", checked: selectedUsers.includes(user.id), onChange: function () { return handleUserSelection(user.id); }, className: "rounded border-gray-300 accent-blue-600" }),
                            React.createElement("div", { className: "w-8 h-8 bg-blue-200 rounded-full" }),
                            React.createElement("span", { className: "" }, "".concat(user.first_name, " ").concat(user.last_name)),
                            React.createElement("span", { className: "text-sm text-gray-500 ml-2" }, user.role))); })))))),
        React.createElement("div", { className: "modal-bg", onClick: onClose })));
};
export default VisibilitySettings;
