var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var CollaboratorsLocal = /** @class */ (function (_super) {
    __extends(CollaboratorsLocal, _super);
    function CollaboratorsLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.updateVisibility = function (e) {
            _this.props.setVisibility(e.target.value);
        };
        _this.state = {};
        return _this;
    }
    CollaboratorsLocal.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var _d = this.props, data = _d.data, addGroup = _d.addGroup, removeGroup = _d.removeGroup, addUser = _d.addUser, removeUser = _d.removeUser;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row field" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("label", null, "Set Visibility"),
                    React.createElement("hr", { className: "tp" }),
                    React.createElement("div", { className: "row visibility-options" },
                        React.createElement("div", { className: "".concat(this.props.data.Visibility == "private" ? "active" : "", " col-12 option"), onClick: function () { _this.props.setVisibility("private"); } },
                            React.createElement("div", { className: "option-wrapper" },
                                React.createElement("h2", { className: "font-alt-2" },
                                    "Private ",
                                    React.createElement("i", { className: "icon-status" }),
                                    " ")))))),
            data.CurrentProject != null ?
                React.createElement("div", { className: "row field project-data" },
                    React.createElement("div", { className: "col-6" },
                        "Groups added from Project: ",
                        data.CurrentProject.project_name,
                        React.createElement("div", { className: "row" }, (_b = (_a = data === null || data === void 0 ? void 0 : data.CurrentProject) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.map(function (g, i) {
                            var _a;
                            return (React.createElement("div", { className: "col-12 group", key: i },
                                React.createElement("div", { className: "wrapper" },
                                    React.createElement("span", { className: "group-name" }, g.name),
                                    React.createElement("ul", null, (_a = g.users) === null || _a === void 0 ? void 0 : _a.map(function (u, x) {
                                        return (React.createElement("li", { key: x },
                                            u.first_name,
                                            " ",
                                            u.last_name));
                                    })))));
                        }))),
                    React.createElement("div", { className: "col-6" },
                        "Users added from Project: ",
                        data.CurrentProject.project_name,
                        React.createElement("div", { className: "row" }, (_c = data.CurrentProject.users) === null || _c === void 0 ? void 0 : _c.map(function (u, i) {
                            return (React.createElement("div", { className: "col-12 user", key: i },
                                React.createElement("div", { className: "wrapper" },
                                    u.first_name,
                                    " ",
                                    u.last_name)));
                        }))))
                : null));
    };
    return CollaboratorsLocal;
}(React.Component));
var Collaborators = CollaboratorsLocal;
export default Collaborators;
