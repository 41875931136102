var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { USER_POST_REQUEST } from "helpers/requests";
import React from "react";
import { connect } from "react-redux";
var mapState = function (state) { return ({
    LoggedIn: state.App.LoggedIn,
    User: state.App.User,
    Organization: state.App.Organization,
}); };
var mapDispatch = {
    SetAppLoaded: function (loaded) { return ({
        type: "SET_APP_LOADED",
        loaded: loaded,
    }); },
};
var connector = connect(mapState, mapDispatch);
var SeatsViewLocal = /** @class */ (function (_super) {
    __extends(SeatsViewLocal, _super);
    function SeatsViewLocal(props) {
        var _this = this;
        var _a, _b, _c;
        _this = _super.call(this, props) || this;
        _this.validateEmailDomain = function (email) {
            var domain = _this.state.domain;
            var domainRegex = new RegExp("@".concat(domain, "$"));
            return domainRegex.test(email);
        };
        _this.validateEmailUnique = function (email) {
            return _this.state.members.every(function (member) { return member.email !== email; });
        };
        _this.addMember = function (first_name, last_name, email, role) { return __awaiter(_this, void 0, void 0, function () {
            var newMember;
            var _this = this;
            return __generator(this, function (_a) {
                this.setState({
                    addingMember: true,
                    errors: [],
                    addingMemberSuccess: false,
                    addingMemberError: false,
                    addingMemberErrorMessage: "",
                });
                if (!this.validateEmailDomain(email)) {
                    this.setState({
                        errors: ["Email must be from the ".concat(this.state.domain, " domain.")],
                    });
                    return [2 /*return*/];
                }
                if (!this.validateEmailUnique(email)) {
                    this.setState({ errors: ["Email must be unique."] });
                    return [2 /*return*/];
                }
                if (role === "admin" && this.getUnassignedAdminSeats() === 0)
                    return [2 /*return*/];
                if (role === "user" && this.getUnassignedUserSeats() === 0)
                    return [2 /*return*/];
                newMember = { first_name: first_name, last_name: last_name, email: email, role: role, id: "" };
                USER_POST_REQUEST("add-organization-member", newMember, function (res) {
                    _this.setState({
                        addingMemberSuccess: true,
                        addingMemberError: false,
                        addingMemberErrorMessage: "",
                    });
                    newMember.id = res.id;
                    _this.setState(function (prevState) { return ({
                        members: __spreadArray(__spreadArray([], prevState.members, true), [newMember], false),
                        errors: [],
                        addingMember: false,
                    }); });
                }, function (err) {
                    _this.setState({
                        addingMemberSuccess: false,
                        addingMemberError: true,
                        addingMemberErrorMessage: err.response.data.message,
                    });
                });
                this.setState({ addingMember: false });
                return [2 /*return*/];
            });
        }); };
        _this.deleteMember = function (email) {
            // this.setState((prevState) => ({
            //   members: prevState.members.filter((member) => member.email !== email),
            // }));
            _this.deleteMemberFromBackend(email);
        };
        _this.deleteMemberFromBackend = function (email) {
            var memberToDelete = _this.state.members.find(function (m) { return m.email === email; });
            if (!memberToDelete)
                return;
            if (memberToDelete.id) {
                USER_POST_REQUEST("remove-organization-member", { email: email }, function (res) {
                    console.log("Member removed successfully");
                    _this.setState(function (prevState) { return ({
                        members: prevState.members.filter(function (member) { return member.email !== email; }),
                    }); });
                }, function (err) {
                    console.error("Error removing member", err);
                });
            }
            else {
                _this.setState(function (prevState) { return ({
                    members: prevState.members.filter(function (member) { return member.email !== email; }),
                }); });
            }
        };
        _this.editMember = function (oldEmail, first_name, last_name, newEmail) {
            _this.setState({ isEditing: true });
            var memberToEdit = _this.state.members.find(function (m) { return m.email === oldEmail; });
            if (!memberToEdit)
                return;
            if (memberToEdit.id) {
                USER_POST_REQUEST("update-organization-member", {
                    id: memberToEdit.id,
                    first_name: first_name,
                    last_name: last_name,
                    email: newEmail,
                    role: memberToEdit.role,
                }, function (res) {
                    console.log("Member removed successfully");
                }, function (err) {
                    console.error("Error removing member", err);
                });
            }
            _this.setState(function (prevState) { return ({
                members: prevState.members.map(function (member) {
                    return member.email === oldEmail
                        ? __assign(__assign({}, member), { first_name: first_name, last_name: last_name, email: newEmail, id: undefined }) : member;
                }),
            }); });
            _this.setState({ isEditing: false });
        };
        _this.getUnassignedAdminSeats = function () {
            var assignedAdmins = _this.state.members.filter(function (m) { return m.role === "admin"; }).length;
            return _this.state.admin_seats - assignedAdmins;
        };
        _this.getUnassignedUserSeats = function () {
            var assignedUsers = _this.state.members.filter(function (m) { return m.role === "user"; }).length;
            return _this.state.user_seats - assignedUsers;
        };
        _this.addMembers = function () {
            _this.setState({ addingMembers: true });
            var membersToAdd = _this.state.members.filter(function (m) { return !m.isCurrentAdmin && !m.id; });
            USER_POST_REQUEST("add-organization-members", membersToAdd, function (res) {
                _this.setState({
                    addingMemberSuccess: true,
                    addingMemberError: false,
                    addingMemberErrorMessage: "",
                });
                _this.setState(function (prevState) { return ({
                    members: prevState.members.map(function (member) {
                        return membersToAdd.includes(member)
                            ? __assign(__assign({}, member), { id: Math.random().toString() }) : member;
                    }),
                }); });
            }, function (err) {
                _this.setState({
                    addingMemberSuccess: false,
                    addingMemberError: true,
                    addingMemberErrorMessage: err.response.data.message,
                });
            });
            _this.setState({ addingMembers: false });
        };
        _this.state = {
            admin_seats: (_a = props === null || props === void 0 ? void 0 : props.Organization) === null || _a === void 0 ? void 0 : _a.admin_seats,
            user_seats: (_b = props === null || props === void 0 ? void 0 : props.Organization) === null || _b === void 0 ? void 0 : _b.user_seats,
            members: __spreadArray([], props.users.map(function (user) { return ({
                id: user.id,
                isCurrentAdmin: user.id === props.User.id,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                role: user.admin || user.leader ? "admin" : "user",
            }); }), true),
            domain: (_c = props === null || props === void 0 ? void 0 : props.Organization) === null || _c === void 0 ? void 0 : _c.domain,
            errors: [],
            addingMembers: false,
            addingMember: false,
        };
        return _this;
    }
    SeatsViewLocal.prototype.render = function () {
        var _this = this;
        var users = this.props.users;
        if (users == undefined) {
            return React.createElement("div", null, "Loading...");
        }
        return (React.createElement("div", { className: "seats-view ", style: { backgroundColor: "#262C38" } },
            React.createElement("div", { className: " bg-[#2F3744]  <!-- padding: 40px 0 -->  w-full <!-- var(--accent-dark) --> py-10 mb-10 <!-- margin-bottom: 40px -->" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h4", { className: "overview", style: { cursor: "pointer" } },
                                React.createElement("a", { className: "text-[#999999] <!-- var(--brown-grey) --> no-underline  text-xs  font-bold  mb-4 transition-colors duration-400 hover:text-[#98C13D] <!-- var(--yellow-green) --> hover:cursor-pointer", onClick: function () {
                                        _this.props.updateView("default");
                                    } }, "<",
                                    " Back to Administration Dashboard")),
                            React.createElement("h1", { className: "text-3xl pt-3 font-alt font-mono uppercase font-alt font-light" }, "MANAGE SEATS"))))),
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "seats-view__cards" },
                            React.createElement("div", { style: { paddingTop: "10px" } },
                                React.createElement("div", { className: "seat-card" },
                                    React.createElement("div", { className: "seat-card__header" },
                                        React.createElement("span", { className: "seat-card__number", style: { fontSize: "96px", fontWeight: "bold" } }, this.state.admin_seats),
                                        React.createElement("span", { className: "seat-card__type font-alt", style: {
                                                fontWeight: "bold",
                                                fontSize: "16px",
                                                textTransform: "uppercase",
                                            } },
                                            React.createElement("span", { style: {
                                                    color: "#BBFF00",
                                                    textTransform: "uppercase",
                                                } }, "Administrator"),
                                            " ",
                                            "Seats"),
                                        React.createElement("span", { className: "seat-card__unassigned font-alt", style: {
                                                color: "white",
                                                fontFamily: "IBM Plex Mono",
                                                fontSize: "17px",
                                                fontWeight: 600,
                                                textTransform: "uppercase",
                                            } },
                                            this.getUnassignedAdminSeats(),
                                            " Unassigned"))),
                                React.createElement(MemberList, { isEditing: this.state.isEditing, members: this.state.members.filter(function (m) { return m.role === "admin"; }), onDelete: this.deleteMember, onEdit: this.editMember }),
                                React.createElement(MemberForm, { onSubmit: function (first_name, last_name, email) {
                                        return _this.addMember(first_name, last_name, email, "admin");
                                    }, disabled: this.getUnassignedAdminSeats() === 0, error: this.state.addingMemberErrorMessage })),
                            React.createElement("div", { style: { paddingTop: "10px" } },
                                React.createElement("div", { className: "seat-card" },
                                    React.createElement("div", { className: "seat-card__header" },
                                        React.createElement("span", { className: "seat-card__number", style: { fontSize: "96px", fontWeight: "bold" } }, this.state.user_seats),
                                        React.createElement("span", { className: "seat-card__type font-alt", style: {
                                                fontWeight: "bold",
                                                fontSize: "16px",
                                                textTransform: "uppercase",
                                            } },
                                            React.createElement("span", { style: {
                                                    color: "#BBFF00",
                                                    textTransform: "uppercase",
                                                } }, "User"),
                                            " ",
                                            "Seats"),
                                        React.createElement("span", { className: "seat-card__unassigned font-alt", style: {
                                                color: "white",
                                                fontFamily: "IBM Plex Mono",
                                                fontSize: "17px",
                                                fontWeight: 600,
                                                textTransform: "uppercase",
                                            } },
                                            this.getUnassignedUserSeats(),
                                            " Unassigned"))),
                                React.createElement(MemberList, { isEditing: this.state.isEditing, members: this.state.members.filter(function (m) { return m.role === "user"; }), onDelete: this.deleteMember, onEdit: this.editMember }),
                                React.createElement(MemberForm, { onSubmit: function (first_name, last_name, email) {
                                        return _this.addMember(first_name, last_name, email, "user");
                                    }, disabled: this.getUnassignedUserSeats() === 0, error: this.state.addingMemberErrorMessage }))),
                        React.createElement("div", { style: { display: "flex", justifyContent: "end" } }, this.state.errors.length > 0 && (React.createElement("div", { className: "error-message", style: { marginTop: "10px" } }, this.state.errors.map(function (error, index) { return (React.createElement("p", { key: index, style: { color: "red" } }, error)); })))),
                        React.createElement("div", { style: {
                                display: "flex",
                                justifyContent: "end",
                                paddingTop: "10px",
                            } }))))));
    };
    return SeatsViewLocal;
}(React.Component));
var MemberForm = /** @class */ (function (_super) {
    __extends(MemberForm, _super);
    function MemberForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { first_name: "", last_name: "", email: "", addingMember: false };
        _this.handleSubmit = function (e) {
            return new Promise(function (resolve, reject) {
                e.preventDefault();
                _this.setState({ addingMember: true });
                _this.props
                    .onSubmit(_this.state.first_name, _this.state.last_name, _this.state.email)
                    .then(function () {
                    _this.setState({ addingMember: false });
                    resolve();
                })
                    .catch(function (error) {
                    console.error("Error adding member:", error);
                    _this.setState({ addingMember: false });
                    reject(error);
                });
            });
        };
        return _this;
    }
    MemberForm.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement("form", { className: "member-form", onSubmit: this.handleSubmit },
                React.createElement("input", { type: "text", placeholder: "First Name", className: "!rounded", value: this.state.first_name, onChange: function (e) { return _this.setState({ first_name: e.target.value }); }, name: "First Name" }),
                React.createElement("input", { type: "text", className: "!rounded", placeholder: "Last Name", value: this.state.last_name, onChange: function (e) { return _this.setState({ last_name: e.target.value }); }, name: "Last Name" }),
                React.createElement("input", { type: "email", className: "!rounded", placeholder: "Email", value: this.state.email, onChange: function (e) { return _this.setState({ email: e.target.value }); } }),
                React.createElement("button", { className: "!px-2 btn btn-blue", type: "submit", disabled: this.props.disabled || this.state.addingMember }, this.state.addingMember ? "Assigning..." : "Assign")),
            this.props.error && (React.createElement("div", { className: "text-red-500 mt-2" }, this.props.error))));
    };
    return MemberForm;
}(React.Component));
var MemberList = function (_a) {
    var isEditing = _a.isEditing, members = _a.members, onDelete = _a.onDelete, onEdit = _a.onEdit;
    var _b = React.useState(null), editingMember = _b[0], setEditingMember = _b[1];
    var _c = React.useState(""), editFirstName = _c[0], setEditFirstName = _c[1];
    var _d = React.useState(""), editLastName = _d[0], setEditLastName = _d[1];
    var _e = React.useState(""), editEmail = _e[0], setEditEmail = _e[1];
    var _f = React.useState(false), isSaving = _f[0], setIsSaving = _f[1];
    var startEdit = function (member) {
        setEditingMember(member.email);
        setEditFirstName(member.first_name);
        setEditLastName(member.last_name);
        setEditEmail(member.email);
    };
    var saveEdit = function (oldEmail) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSaving(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, onEdit(oldEmail, editFirstName, editLastName, editEmail)];
                case 2:
                    _a.sent();
                    setEditingMember(null);
                    setEditEmail(null);
                    return [3 /*break*/, 4];
                case 3:
                    setIsSaving(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "member-list font-alt" },
        React.createElement("div", { className: "member-list__header" },
            React.createElement("span", { style: { color: "#898989" } }, "Name"),
            React.createElement("span", { style: { color: "#898989" } }, "Email"),
            React.createElement("span", { style: { color: "#898989" } })),
        members.map(function (member, index) { return (React.createElement("div", { key: index + 1, className: "flex flex-wrap py-2 gap-2 items-center  justify-between" }, editingMember === member.email ? (React.createElement(React.Fragment, null,
            React.createElement("input", { type: "text", className: "flex-1 p-1 border rounded border-[#ddd] bg-black text-white", value: editFirstName, onChange: function (e) { return setEditFirstName(e.target.value); } }),
            React.createElement("input", { type: "text", className: "flex-1 p-1 border rounded border-[#ddd] bg-black text-white", value: editLastName, onChange: function (e) { return setEditLastName(e.target.value); } }),
            React.createElement("input", { type: "email", className: "flex-1 p-1 border rounded border-[#ddd] bg-black text-white", value: editEmail, onChange: function (e) { return setEditEmail(e.target.value); } }),
            React.createElement("div", { className: "flex-1 flex items-center gap-2" },
                React.createElement("button", { className: "!px-2 !py-1 btn btn-blue", onClick: function () { return saveEdit(member.email); }, disabled: isSaving }, isSaving ? "Saving..." : "Save"),
                React.createElement("button", { className: "!px-2 !py-1 btn btn-blue", onClick: function () { return setEditingMember(null); } }, "Cancel")))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "font-alt" },
                index + 1 + "  ",
                " ",
                member.first_name,
                " ",
                member.last_name),
            React.createElement("span", { className: "font-alt" }, member.email),
            member.isCurrentAdmin ? (React.createElement("span", { className: "font-alt pl-3" }, "You")) : (React.createElement("div", { className: "" },
                React.createElement("button", { className: "!px-2 !py-1 btn btn-blue", onClick: function () { return startEdit(member); } }, "Edit"))))))); })));
};
var SeatsView = connector(SeatsViewLocal);
export default SeatsView;
