var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UpdateInput } from 'helpers/inputHandlers';
import { FORMDATA_REQUEST, USER_POST_REQUEST } from 'helpers/requests';
import React from 'react';
var ProjectSettingsOverview = /** @class */ (function (_super) {
    __extends(ProjectSettingsOverview, _super);
    function ProjectSettingsOverview(props) {
        var _this = _super.call(this, props) || this;
        _this.handleImage = function (e) {
            var files = e.target.files;
            if (files.length == 0) {
                return;
            }
            _this.setState({
                newProjectImage: files[0] || null
            });
        };
        _this.state = {
            projectNameOriginal: props.project.name || "",
            newProjectNameString: props.project.name || "",
            newProjectImage: null,
        };
        _this.cancelChange = _this.cancelChange.bind(_this);
        _this.updateInput = UpdateInput.bind(_this);
        _this.updateProject = _this.updateProject.bind(_this);
        _this.fileRef = React.createRef();
        _this.getImage = _this.getImage.bind(_this);
        _this.clearImage = _this.clearImage.bind(_this);
        _this.updateProjectImage = _this.updateProjectImage.bind(_this);
        _this.updateProjectName = _this.updateProjectName.bind(_this);
        return _this;
    }
    ProjectSettingsOverview.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.project.id != this.props.project.id) {
            this.setState({
                projectNameOriginal: this.props.project.name,
                newProjectNameString: this.props.project.name
            });
        }
    };
    ProjectSettingsOverview.prototype.getImage = function () {
        this.fileRef.current.click();
    };
    ProjectSettingsOverview.prototype.clearImage = function () {
        this.fileRef.current.value = null;
        this.setState({
            newProjectImage: null,
        });
    };
    ProjectSettingsOverview.prototype.cancelChange = function () {
        var projectNameOriginal = this.state.projectNameOriginal;
        this.setState({
            newProjectNameString: projectNameOriginal
        });
    };
    ProjectSettingsOverview.prototype.updateProjectImage = function () {
        var _this = this;
        var newProjectImage = this.state.newProjectImage;
        var project = this.props.project;
        var fd = new FormData;
        fd.append("thumbnail", newProjectImage);
        fd.append("project", this.props.project.id);
        FORMDATA_REQUEST("update-project-image", fd, function (res) {
            _this.setState({
                newProjectImage: null,
            });
            project.thumbnail = res.thumbnail;
            _this.fileRef.current.value = null;
            _this.props.updateProject(project);
        }, function (err) {
            console.log(err);
        });
    };
    ProjectSettingsOverview.prototype.updateProjectName = function () {
        var _this = this;
        var newProjectNameString = this.state.newProjectNameString;
        var project = this.props.project;
        USER_POST_REQUEST("update-project-name", { project: this.props.project.id, name: newProjectNameString }, function (res) {
            console.log(res);
            _this.setState({
                newProjectNameString: newProjectNameString,
                projectNameOriginal: newProjectNameString,
            });
            project.name = newProjectNameString;
            _this.props.updateProject(project);
        }, function (err) {
            console.log(err);
        });
    };
    ProjectSettingsOverview.prototype.updateProject = function () {
        var _this = this;
        var project = this.props.project;
        USER_POST_REQUEST("update-project", { project: this.props.project.id, name: this.state.newProjectNameString }, function (res) {
            project.name = _this.state.newProjectNameString;
            _this.setState({
                newProjectNameString: _this.state.newProjectNameString,
                projectNameOriginal: _this.state.newProjectNameString,
            });
            _this.props.updateProject(project);
        }, function (err) {
            console.log(err);
        });
    };
    ProjectSettingsOverview.prototype.render = function () {
        var _a = this.state, projectNameOriginal = _a.projectNameOriginal, newProjectNameString = _a.newProjectNameString;
        var project = this.props.project;
        return (React.createElement("div", { className: "row project-settings" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-6" },
                        React.createElement("h3", { className: "font-light font-alt" }, "Update Name:"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 gap-1" },
                                React.createElement("input", { className: 'text-black', onChange: this.updateInput, type: "text", value: this.state.newProjectNameString, name: "newProjectNameString" })),
                            projectNameOriginal != newProjectNameString ?
                                React.createElement("div", { className: "col-12 pt-3 submit" },
                                    React.createElement("button", { className: "btn btn-cancel-alt", onClick: this.cancelChange }, "Cancel Changes"),
                                    React.createElement("span", { className: "btn-separator" }),
                                    React.createElement("button", { className: "btn btn-blue-alt", onClick: this.updateProjectName }, "Submit Project Changes"))
                                : null)),
                    React.createElement("div", { className: "col-6" },
                        React.createElement("h3", { className: "font-light font-alt" }, "Update Thumbnail:"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" }, project.thumbnail != "" && project.thumbnail != null ?
                                React.createElement("img", { className: "thumbnail", src: project.thumbnail })
                                : null),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("hr", { className: "tp" }),
                                React.createElement("input", { type: "file", hidden: true, onChange: this.handleImage, ref: this.fileRef }),
                                React.createElement("button", { className: "btn btn-blue-alt", onClick: this.getImage }, "Edit Thumbnail")),
                            this.state.newProjectImage != null ?
                                React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("hr", { className: "tp" }),
                                        React.createElement("img", { src: URL.createObjectURL(this.state.newProjectImage) })),
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("hr", { className: "tp" }),
                                        React.createElement("button", { className: "btn btn-cancel-alt", onClick: this.clearImage }, "Clear"),
                                        React.createElement("span", { className: "btn-separator" }),
                                        React.createElement("button", { onClick: this.updateProjectImage, className: "btn btn-blue-alt" }, "Submit")))
                                : null))))));
    };
    return ProjectSettingsOverview;
}(React.Component));
export default ProjectSettingsOverview;
