var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { UpdateInput } from "helpers/inputHandlers";
import { FORMDATA_REQUEST, USER_POST_REQUEST } from "helpers/requests";
import { TimeSince } from "helpers/timesince";
import React from "react";
var GroupSettingsOverview = /** @class */ (function (_super) {
    __extends(GroupSettingsOverview, _super);
    function GroupSettingsOverview(props) {
        var _this = _super.call(this, props) || this;
        _this.removeMember = function (id) { };
        _this.removeMembers = function (removeMem) {
            var currentGroup = _this.props.currentGroup;
            var idList = [];
            for (var i = 0; i < removeMem.length; i++) {
                idList.push(removeMem[i]);
            }
            currentGroup.members = currentGroup.members.filter(function (mem) {
                return idList.indexOf(mem.id) == -1;
            });
            _this.props.updateGroup(currentGroup);
        };
        _this.updateView = function (view) {
            _this.setState({
                view: view,
            });
        };
        _this.addMembers = function (newMem) {
            var currentGroup = _this.props.currentGroup;
            var newMembers = [];
            var _loop_1 = function (i) {
                newMembers.push(_this.props.members.filter(function (mf) {
                    return mf.id == newMem[i];
                })[0]);
            };
            for (var i = 0; i < newMem.length; i++) {
                _loop_1(i);
            }
            for (var i = 0; i < newMembers.length; i++) {
                var temp = newMembers[i];
                temp.created = Math.round(Date.now() / 1000 - 5);
                newMembers[i] = temp;
            }
            currentGroup.members = __spreadArray(__spreadArray([], currentGroup.members, true), (newMembers || []), true);
            _this.props.updateGroup(currentGroup);
        };
        _this.addMember = function (userID) { };
        _this.updateGroup = function (group) {
            _this.props.updateGroup(group);
        };
        _this.state = {
            groupLoaded: false,
            view: "members",
        };
        _this.removeMember = _this.removeMember.bind(_this);
        _this.removeMembers = _this.removeMembers.bind(_this);
        _this.deleteGroup = _this.deleteGroup.bind(_this);
        _this.addMembers = _this.addMembers.bind(_this);
        _this.addMember = _this.addMember.bind(_this);
        _this.updateView = _this.updateView.bind(_this);
        _this.updateGroup = _this.updateGroup.bind(_this);
        return _this;
    }
    GroupSettingsOverview.prototype.deleteGroup = function () { };
    GroupSettingsOverview.prototype.render = function () {
        var _this = this;
        var GroupMembersProps = {
            members: this.props.members,
        };
        var GroupActivityProps = {};
        var view = this.state.view;
        var currentGroup = this.props.currentGroup;
        return (React.createElement("div", { className: "container-fluid group-page" },
            React.createElement("div", { className: "row settings-header" },
                React.createElement("div", { className: "container" },
                    React.createElement("h2", { className: "font-alt font-light" },
                        currentGroup.name,
                        " Settings"))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row group-controls" },
                        React.createElement("div", { onClick: function () {
                                _this.updateView("members");
                            }, className: "col-4 br t-c selectable ".concat(view == "members" ? "active" : "") },
                            React.createElement("h2", { className: "font-alt-2" }, "Members")),
                        React.createElement("div", { onClick: function () {
                                _this.updateView("activity");
                            }, className: "col-4 br t-c selectable ".concat(view == "activity" ? "active" : "") },
                            React.createElement("h2", { className: "font-alt-2" }, "Activity")),
                        React.createElement("div", { onClick: function () {
                                _this.updateView("settings");
                            }, className: "col-4 t-c selectable ".concat(view == "settings" ? "active" : "") },
                            React.createElement("h2", { className: "font-alt-2" }, "Name/Thumbnail"))),
                    view == "members" ? (React.createElement(GroupMembersView, { groupID: currentGroup.id, members: this.props.members, groupMembers: this.props.currentGroup.members, addMembers: this.addMembers, removeMembers: this.removeMembers })) : view == "activity" ? (React.createElement(GroupActivityView, null)) : view == "settings" ? (React.createElement(GroupSettings, { group: this.props.currentGroup, updateGroup: this.updateGroup })) : null))));
    };
    return GroupSettingsOverview;
}(React.Component));
var GroupMembersView = /** @class */ (function (_super) {
    __extends(GroupMembersView, _super);
    function GroupMembersView(props) {
        var _this = _super.call(this, props) || this;
        _this.select = function (id) {
            var selected = _this.state.selected;
            if (selected.indexOf(id) != -1) {
                return;
            }
            _this.setState({
                selected: __spreadArray(__spreadArray([], selected, true), [id], false),
            });
        };
        _this.deselect = function (id) {
            _this.setState(function (prev) { return ({
                selected: prev.selected.filter(function (mid) {
                    return mid != id;
                }),
            }); });
        };
        _this.updateView = function (view) {
            _this.setState({
                view: view,
                selected: [],
            });
        };
        _this.state = {
            view: "members",
            submitting: false,
            selected: [],
        };
        _this.updateView = _this.updateView.bind(_this);
        _this.select = _this.select.bind(_this);
        _this.clear = _this.clear.bind(_this);
        _this.deselect = _this.deselect.bind(_this);
        _this.addToGroup = _this.addToGroup.bind(_this);
        _this.removeFromGroup = _this.removeFromGroup.bind(_this);
        return _this;
    }
    GroupMembersView.prototype.clear = function () {
        this.setState({
            selected: [],
        });
    };
    GroupMembersView.prototype.removeFromGroup = function () {
        var _this = this;
        var _a = this.state, submitting = _a.submitting, selected = _a.selected;
        if (submitting) {
            return;
        }
        this.setState({
            submitting: true,
        }, function () {
            USER_POST_REQUEST("remove-members-from-group", { group: _this.props.groupID, users: _this.state.selected }, function (res) {
                _this.props.removeMembers(selected);
                _this.setState({
                    selected: [],
                    submitting: false,
                    view: "members",
                });
            }, function (err) {
                console.log(err);
            });
        });
    };
    GroupMembersView.prototype.addToGroup = function () {
        var _this = this;
        var submitting = this.state.submitting;
        if (submitting) {
            return;
        }
        var selected = this.state.selected;
        this.setState({
            submitting: true,
        }, function () {
            USER_POST_REQUEST("add-members-to-group", { group: _this.props.groupID, users: _this.state.selected }, function (res) {
                _this.props.addMembers(selected);
                _this.setState({
                    selected: [],
                    submitting: false,
                    view: "members",
                });
            }, function (err) { });
        });
    };
    GroupMembersView.prototype.render = function () {
        var _this = this;
        var _a = this.state, view = _a.view, selected = _a.selected;
        var _b = this.props, members = _b.members, groupMembers = _b.groupMembers;
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "container" },
                React.createElement("hr", { className: "tp" }),
                React.createElement("hr", { className: "tp" }),
                React.createElement("hr", { className: "tp" }),
                React.createElement("div", { className: "row group-members-controls" },
                    React.createElement("div", { className: "col-4 selector br ".concat(view == "members" ? "active" : ""), onClick: function () {
                            _this.updateView("members");
                        } }, "Members in Group"),
                    React.createElement("div", { className: "col-4 selector br ".concat(view == "add-members" ? "active" : ""), onClick: function () {
                            _this.updateView("add-members");
                        } }, "Add Members to Group"),
                    React.createElement("div", { className: "col-4 selector ".concat(view == "remove-members" ? "active" : ""), onClick: function () {
                            _this.updateView("remove-members");
                        } }, "Remove Members From Group")),
                React.createElement("div", { className: "row group-members-content" },
                    view == "members" ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "col-12" }, groupMembers == null || groupMembers.length == 0 ? (React.createElement("h2", null, "No members in this group, try adding some.")) : (
                        // <table className="table group-members-table">
                        //   <thead>
                        //     <tr>
                        //       <td>Name: </td>
                        //       <td>Added: </td>
                        //     </tr>
                        //   </thead>
                        //   <tbody>
                        //     {groupMembers.map((gm: any, index: number) => {
                        //       return (
                        //         <tr key={index}>
                        //           <td>
                        //             {gm.name == null || gm.name == ""
                        //               ? `${gm.first_name} ${gm.last_name}`
                        //               : gm.name}
                        //           </td>
                        //           <td>{TimeSince(gm.created)}</td>
                        //         </tr>
                        //       );
                        //     })}
                        //   </tbody>
                        // </table>
                        React.createElement("div", { className: "w-full flex flex-col" },
                            React.createElement("div", { className: "flex font-semibold border-b border-gray-200 pb-2 mb-2" },
                                React.createElement("div", { className: "w-1/2 px-4" }, "Name:"),
                                React.createElement("div", { className: "w-1/2 px-4" }, "Added:")),
                            React.createElement("div", { className: "flex flex-col" }, groupMembers.map(function (gm, index) { return (React.createElement("div", { key: index, className: "flex py-2  border-gray-100" },
                                React.createElement("div", { className: "w-1/2 px-4" }, gm.name == null || gm.name == ""
                                    ? "".concat(gm.first_name, " ").concat(gm.last_name)
                                    : gm.name),
                                React.createElement("div", { className: "w-1/2 px-4" }, TimeSince(gm.created)))); }))))))) : null,
                    view == "add-members" ? (React.createElement(React.Fragment, null,
                        selected.length > 0 ? (React.createElement("div", { className: "col-12 add-members-controls" },
                            React.createElement("button", { className: "btn btn-blue-alt", onClick: this.addToGroup }, "Add Selected to Group"),
                            React.createElement("span", { className: "btn-separator" }),
                            React.createElement("button", { className: "btn btn-cancel-alt", onClick: function () {
                                    _this.clear();
                                } }, "Clear Selected"))) : null,
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "w-full flex flex-col" },
                                React.createElement("div", { className: "flex font-semibold border-b border-gray-200 pb-2 mb-2" },
                                    React.createElement("div", { className: "w-12 px-4" }),
                                    React.createElement("div", { className: "flex-1 px-4" }, "Name")),
                                React.createElement("div", { className: "flex flex-col" }, members.map(function (member, index) {
                                    if (groupMembers.filter(function (m) {
                                        return m.id == member.id;
                                    }).length > 0) {
                                        return null;
                                    }
                                    return (React.createElement("div", { key: index, className: "flex items-center py-2  border-gray-100 cursor-pointer\n                                    ".concat(_this.state.selected.indexOf(member.id) != -1 ? "" : ""), onClick: function () {
                                            _this.state.selected.indexOf(member.id) == -1
                                                ? _this.select(member.id)
                                                : _this.deselect(member.id);
                                        } },
                                        React.createElement("div", { className: "w-12 px-4" },
                                            React.createElement("input", { type: "checkbox", checked: _this.state.selected.indexOf(member.id) != -1, onChange: function () { } })),
                                        React.createElement("div", { className: "flex-1 px-4" },
                                            member.first_name,
                                            " ",
                                            member.last_name,
                                            " ")));
                                })))))) : null,
                    view == "remove-members" ? (React.createElement(React.Fragment, null,
                        selected.length > 0 ? (React.createElement("div", { className: "col-12 add-members-controls" },
                            React.createElement("button", { className: "btn btn-blue-alt", onClick: this.removeFromGroup }, "Remove Selected From Group"),
                            React.createElement("span", { className: "btn-separator" }),
                            React.createElement("button", { className: "btn btn-cancel-alt", onClick: function () {
                                    _this.clear();
                                } }, "Clear Selected"))) : null,
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "w-full flex flex-col" },
                                React.createElement("div", { className: "flex font-semibold border-b border-gray-200 pb-2 mb-2" },
                                    React.createElement("div", { className: "w-12 px-4" }),
                                    React.createElement("div", { className: "flex-1 px-4" }, "Name")),
                                React.createElement("div", { className: "flex flex-col" }, groupMembers.map(function (member, index) { return (React.createElement("div", { key: index, className: "flex items-center py-2   border-gray-100 cursor-pointer\n                            ".concat(_this.state.selected.indexOf(member.id) != -1
                                        ? ""
                                        : ""), onClick: function () {
                                        _this.state.selected.indexOf(member.id) == -1
                                            ? _this.select(member.id)
                                            : _this.deselect(member.id);
                                    } },
                                    React.createElement("div", { className: "w-12 px-4" },
                                        React.createElement("input", { type: "checkbox", checked: _this.state.selected.indexOf(member.id) != -1, onChange: function () { } })),
                                    React.createElement("div", { className: "flex-1 px-4" }, member.name == null || member.name == ""
                                        ? "".concat(member.first_name, " ").concat(member.last_name)
                                        : member.name))); })))))) : null))));
    };
    return GroupMembersView;
}(React.Component));
var GroupActivityView = /** @class */ (function (_super) {
    __extends(GroupActivityView, _super);
    function GroupActivityView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GroupActivityView.prototype.render = function () {
        return (React.createElement("div", { className: "row" },
            React.createElement("h1", null, "Coming soon")));
    };
    return GroupActivityView;
}(React.Component));
var GroupSettings = /** @class */ (function (_super) {
    __extends(GroupSettings, _super);
    function GroupSettings(props) {
        var _this = _super.call(this, props) || this;
        _this.handleImage = function (e) {
            var files = e.target.files;
            if (files.length == 0) {
                return;
            }
            _this.setState({
                newGroupImage: files[0] || null,
            });
        };
        _this.state = {
            groupNameOriginal: props.group.name || "",
            newGroupNameString: props.group.name || "",
            newGroupImage: null,
        };
        _this.cancelChange = _this.cancelChange.bind(_this);
        _this.updateInput = UpdateInput.bind(_this);
        _this.updateGroup = _this.updateGroup.bind(_this);
        _this.fileRef = React.createRef();
        _this.getImage = _this.getImage.bind(_this);
        _this.clearImage = _this.clearImage.bind(_this);
        _this.updateGroupImage = _this.updateGroupImage.bind(_this);
        return _this;
    }
    GroupSettings.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.group.id != this.props.group.id) {
            this.setState({
                groupNameOriginal: this.props.group.name,
                newGroupNameString: this.props.group.name,
            });
        }
    };
    GroupSettings.prototype.getImage = function () {
        this.fileRef.current.click();
    };
    GroupSettings.prototype.clearImage = function () {
        this.fileRef.current.value = null;
        this.setState({
            newGroupImage: null,
        });
    };
    GroupSettings.prototype.cancelChange = function () {
        var groupNameOriginal = this.state.groupNameOriginal;
        this.setState({
            newGroupNameString: groupNameOriginal,
        });
    };
    GroupSettings.prototype.updateGroupImage = function () {
        var _this = this;
        var newGroupImage = this.state.newGroupImage;
        var group = this.props.group;
        var fd = new FormData();
        fd.append("thumbnail", newGroupImage);
        fd.append("group", this.props.group.id);
        FORMDATA_REQUEST("update-group-image", fd, function (res) {
            _this.setState({
                newGroupImage: null,
            });
            group.thumbnail = res.thumbnail;
            _this.fileRef.current.value = null;
            _this.props.updateGroup(group);
        }, function (err) {
            console.log(err);
        });
    };
    GroupSettings.prototype.updateGroupName = function () {
        var _this = this;
        var newGroupNameString = this.state.newGroupNameString;
        var group = this.props.group;
        USER_POST_REQUEST("update-group-name", { group: this.props.group.id, name: newGroupNameString }, function (res) {
            console.log(res);
            _this.setState({
                newGroupNameString: newGroupNameString,
                groupNameOriginal: newGroupNameString,
            });
            group.name = newGroupNameString;
            _this.props.updateGroup(group);
        }, function (err) {
            console.log(err);
        });
    };
    GroupSettings.prototype.updateGroup = function () {
        var _this = this;
        var group = this.props.group;
        USER_POST_REQUEST("update-group", { group: this.props.group.id, name: this.state.newGroupNameString }, function (res) {
            group.name = _this.state.newGroupNameString;
            _this.setState({
                newGroupNameString: _this.state.newGroupNameString,
                groupNameOriginal: _this.state.newGroupNameString,
            });
            _this.props.updateGroup(group);
        }, function (err) {
            console.log(err);
        });
    };
    GroupSettings.prototype.render = function () {
        var _a = this.state, groupNameOriginal = _a.groupNameOriginal, newGroupNameString = _a.newGroupNameString;
        var group = this.props.group;
        return (React.createElement("div", { className: "row group-settings" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-6" },
                        React.createElement("h3", { className: "font-light font-alt" }, "Update Name:"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("input", { onChange: this.updateInput, type: "text", value: this.state.newGroupNameString, name: "newGroupNameString" })),
                            groupNameOriginal != newGroupNameString ? (React.createElement("div", { className: "col-12 submit" },
                                React.createElement("button", { className: "btn btn-cancel-alt", onClick: this.cancelChange }, "Cancel Changes"),
                                React.createElement("span", { className: "btn-separator" }),
                                React.createElement("button", { className: "btn btn-blue-alt", onClick: this.updateGroup }, "Submit Group Changes"))) : null)),
                    React.createElement("div", { className: "col-6" },
                        React.createElement("h3", { className: "font-light font-alt" }, "Update Thumbnail:"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" }, group.thumbnail != "" && group.thumbnail != null ? (React.createElement("img", { className: "thumbnail", src: group.thumbnail })) : null),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("hr", { className: "tp" }),
                                React.createElement("input", { type: "file", hidden: true, onChange: this.handleImage, ref: this.fileRef }),
                                React.createElement("button", { className: "btn btn-blue-alt", onClick: this.getImage }, "Edit Thumbnail")),
                            this.state.newGroupImage != null ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "col-12" },
                                    React.createElement("hr", { className: "tp" }),
                                    React.createElement("img", { src: URL.createObjectURL(this.state.newGroupImage) })),
                                React.createElement("div", { className: "col-12" },
                                    React.createElement("hr", { className: "tp" }),
                                    React.createElement("button", { className: "btn btn-cancel-alt", onClick: this.clearImage }, "Clear"),
                                    React.createElement("span", { className: "btn-separator" }),
                                    React.createElement("button", { onClick: this.updateGroupImage, className: "btn btn-blue-alt" }, "Submit")))) : null))))));
    };
    return GroupSettings;
}(React.Component));
export default GroupSettingsOverview;
